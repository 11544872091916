/* tslint:disable */
/* eslint-disable */
/**
 * Public endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * APIError model
 * @export
 * @interface APIError
 */
export interface APIError {
    /**
     * 
     * @type {Error}
     * @memberof APIError
     */
    error: Error;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Текст ошибки
     * @type {string}
     * @memberof ModelError
     */
    message: string;
    /**
     * Код ошибки
     * @type {string}
     * @memberof ModelError
     */
    code: string;
    /**
     * Является ли фатальной
     * @type {boolean}
     * @memberof ModelError
     */
    fatal: boolean;
}
/**
 * POST /v1/analytics/feedback/development_tasks
 * @export
 * @interface PostV1AnalyticsFeedbackDevelopmentTasks
 */
export interface PostV1AnalyticsFeedbackDevelopmentTasks {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasks
     */
    surveyId: number;
    /**
     * ID сотрудника
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasks
     */
    userId: string;
    /**
     * Текст задания
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasks
     */
    name: string;
    /**
     * Планируемая дата завершения (ISO8601)
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasks
     */
    scheduledAt: string;
    /**
     * ID вопроса (компетенция)
     * @type {number}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasks
     */
    questionId?: number;
}
/**
 * POST /v1/analytics/feedback/development_tasks/manual_distribute
 * @export
 * @interface PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute
 */
export interface PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute {
    /**
     * Массив значений весов задач
     * @type {Array<PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks>}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute
     */
    developmentTasks: Array<PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks>;
}
/**
 * 
 * @export
 * @interface PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks
 */
export interface PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks {
    /**
     * ИД задачи
     * @type {number}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks
     */
    id: number;
    /**
     * Вес задачи
     * @type {number}
     * @memberof PostV1AnalyticsFeedbackDevelopmentTasksManualDistributeDevelopmentTasks
     */
    weight: number;
}
/**
 * POST /v1/analytics/feedback/general_questions/dynamics_by_competentions
 * @export
 * @interface PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
 */
export interface PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions {
    /**
     * ID опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
     */
    surveyIds: Array<number>;
    /**
     * ID исторических опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
     */
    historicalSurveyIds?: Array<number>;
    /**
     * ID сотрудника (иначе current_user из JWT токена)
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
     */
    userId?: string;
}
/**
 * POST /v1/analytics/feedback/general_questions/dynamics_by_indicators
 * @export
 * @interface PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
 */
export interface PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators {
    /**
     * ID опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
     */
    surveyIds: Array<number>;
    /**
     * ID исторических опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
     */
    historicalSurveyIds?: Array<number>;
    /**
     * ID сотрудника (иначе current_user из JWT токена)
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
     */
    userId?: string;
}
/**
 * POST /v1/analytics/feedback/nps_questions/dynamics_by_competentions
 * @export
 * @interface PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
 */
export interface PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions {
    /**
     * ID опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
     */
    surveyIds: Array<number>;
    /**
     * ID исторических опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
     */
    historicalSurveyIds?: Array<number>;
    /**
     * ID сотрудника (иначе current_user из JWT токена)
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
     */
    userId?: string;
}
/**
 * POST /v1/analytics/feedback/nps_questions/dynamics_by_indicators
 * @export
 * @interface PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators
 */
export interface PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators {
    /**
     * ID опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators
     */
    surveyIds: Array<number>;
    /**
     * ID исторических опросов
     * @type {Array<number>}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators
     */
    historicalSurveyIds?: Array<number>;
    /**
     * ID сотрудника (иначе current_user из JWT токена)
     * @type {string}
     * @memberof PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators
     */
    userId?: string;
}
/**
 * POST /v1/analytics/management/audiences
 * @export
 * @interface PostV1AnalyticsManagementAudiences
 */
export interface PostV1AnalyticsManagementAudiences {
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementAudiences
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsManagementAudiences
     */
    filters?: object;
}
/**
 * POST /v1/analytics/management/dashboards
 * @export
 * @interface PostV1AnalyticsManagementDashboards
 */
export interface PostV1AnalyticsManagementDashboards {
    /**
     * Критерий поиска (минимум 1 символ, иначе не учитывается)
     * @type {string}
     * @memberof PostV1AnalyticsManagementDashboards
     */
    query?: string;
    /**
     * Выбранная страница
     * @type {number}
     * @memberof PostV1AnalyticsManagementDashboards
     */
    page?: number;
    /**
     * Количество элементов на страницу
     * @type {number}
     * @memberof PostV1AnalyticsManagementDashboards
     */
    limit?: number;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementDashboards
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsManagementDashboards
     */
    filters?: object;
}
/**
 * POST /v1/analytics/management/difference/heatmap
 * @export
 * @interface PostV1AnalyticsManagementDifferenceHeatmap
 */
export interface PostV1AnalyticsManagementDifferenceHeatmap {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsManagementDifferenceHeatmap
     */
    surveyId: number;
    /**
     * ID сотрудников
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementDifferenceHeatmap
     */
    userIds: Array<string>;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementDifferenceHeatmap
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsManagementDifferenceHeatmap
     */
    filters?: object;
}
/**
 * POST /v1/analytics/management/difference/xlsx_report
 * @export
 * @interface PostV1AnalyticsManagementDifferenceXlsxReport
 */
export interface PostV1AnalyticsManagementDifferenceXlsxReport {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsManagementDifferenceXlsxReport
     */
    surveyId: number;
    /**
     * ID сотрудников
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementDifferenceXlsxReport
     */
    userIds: Array<string>;
}
/**
 * POST /v1/analytics/management/expert_lists
 * @export
 * @interface PostV1AnalyticsManagementExpertLists
 */
export interface PostV1AnalyticsManagementExpertLists {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsManagementExpertLists
     */
    surveyId: number;
}
/**
 * POST /v1/analytics/management/result
 * @export
 * @interface PostV1AnalyticsManagementResult
 */
export interface PostV1AnalyticsManagementResult {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsManagementResult
     */
    surveyId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementResult
     */
    userIds?: Array<string>;
    /**
     * Тип сортировки
     * @type {string}
     * @memberof PostV1AnalyticsManagementResult
     */
    orderBy?: PostV1AnalyticsManagementResultOrderBy;
    /**
     * Направление сортировки
     * @type {string}
     * @memberof PostV1AnalyticsManagementResult
     */
    direction?: PostV1AnalyticsManagementResultDirection;
    /**
     * Выбранная страница
     * @type {number}
     * @memberof PostV1AnalyticsManagementResult
     */
    page?: number;
    /**
     * Количество элементов на страницу
     * @type {number}
     * @memberof PostV1AnalyticsManagementResult
     */
    limit?: number;
    /**
     * С учетом или без учеты самооценки
     * @type {boolean}
     * @memberof PostV1AnalyticsManagementResult
     */
    withoutSelf?: boolean;
    /**
     * ID группы эксперта
     * @type {number}
     * @memberof PostV1AnalyticsManagementResult
     */
    expertListId?: number;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementResult
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsManagementResult
     */
    filters?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum PostV1AnalyticsManagementResultOrderBy {
    EMPLOYEE = 'employee',
    BENCH_AVG = 'bench_avg',
    AVERAGE = 'average'
}
/**
    * @export
    * @enum {string}
    */
export enum PostV1AnalyticsManagementResultDirection {
    ASC = 'asc',
    DESC = 'desc'
}

/**
 * POST /v1/analytics/management/subordinate_by_ids
 * @export
 * @interface PostV1AnalyticsManagementSubordinateByIds
 */
export interface PostV1AnalyticsManagementSubordinateByIds {
    /**
     * ID сотрудников
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementSubordinateByIds
     */
    userIds: Array<string>;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementSubordinateByIds
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsManagementSubordinateByIds
     */
    filters?: object;
}
/**
 * POST /v1/analytics/management/surveys
 * @export
 * @interface PostV1AnalyticsManagementSurveys
 */
export interface PostV1AnalyticsManagementSurveys {
    /**
     * IDs сотрудников
     * @type {Array<string>}
     * @memberof PostV1AnalyticsManagementSurveys
     */
    userIds?: Array<string>;
    /**
     * Hack to bypass Grape bug
     * @type {string}
     * @memberof PostV1AnalyticsManagementSurveys
     */
    hack?: string;
}
/**
 * POST /v1/analytics/management/xlsx_report
 * @export
 * @interface PostV1AnalyticsManagementXlsxReport
 */
export interface PostV1AnalyticsManagementXlsxReport {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1AnalyticsManagementXlsxReport
     */
    surveyId: number;
    /**
     * Email получателя отчета
     * @type {string}
     * @memberof PostV1AnalyticsManagementXlsxReport
     */
    recipientEmail: string;
}
/**
 * POST /v1/analytics/overview/choose-experts
 * @export
 * @interface PostV1AnalyticsOverviewChooseExperts
 */
export interface PostV1AnalyticsOverviewChooseExperts {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewChooseExperts
     */
    surveyId: number;
    /**
     * Учитывать роль пользователя?
     * @type {boolean}
     * @memberof PostV1AnalyticsOverviewChooseExperts
     */
    considerRole?: boolean;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewChooseExperts
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewChooseExperts
     */
    filters?: object;
}
/**
 * POST /v1/analytics/overview/choose-experts-list
 * @export
 * @interface PostV1AnalyticsOverviewChooseExpertsList
 */
export interface PostV1AnalyticsOverviewChooseExpertsList {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    surveyId: number;
    /**
     * Критерий поиска (минимум 1 символ, иначе не учитывается)
     * @type {string}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    query?: string;
    /**
     * Менее и/или более N экспертов: less, more
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    withStatus?: Array<string>;
    /**
     * Выбранная страница
     * @type {number}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    page?: number;
    /**
     * Количество элементов на страницу
     * @type {number}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    limit?: number;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewChooseExpertsList
     */
    filters?: object;
}
/**
 * POST /v1/analytics/overview/send-notification/choose-experts
 * @export
 * @interface PostV1AnalyticsOverviewSendNotificationChooseExperts
 */
export interface PostV1AnalyticsOverviewSendNotificationChooseExperts {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    surveyId: number;
    /**
     * Фильтр по статусу пар: less, more
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    withStatus?: Array<string>;
    /**
     * Идентификаторы сотрудников, которым отправляются письма
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    includeUserIds?: Array<string>;
    /**
     * Идентификаторы сотрудников, которым НЕ отправляются письма
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    excludeUserIds?: Array<string>;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewSendNotificationChooseExperts
     */
    filters?: object;
}
/**
 * POST /v1/analytics/overview/send-notification/start-survey
 * @export
 * @interface PostV1AnalyticsOverviewSendNotificationStartSurvey
 */
export interface PostV1AnalyticsOverviewSendNotificationStartSurvey {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    surveyId: number;
    /**
     * Фильтр по статусу пар: pending, running, done
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    withStatus?: Array<string>;
    /**
     * Сотрудников, которым отправляются письма
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    includeUserIds?: Array<string>;
    /**
     * Сотрудников, которым НЕ отправляются письма
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    excludeUserIds?: Array<string>;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewSendNotificationStartSurvey
     */
    filters?: object;
}
/**
 * POST /v1/analytics/overview/start-survey
 * @export
 * @interface PostV1AnalyticsOverviewStartSurvey
 */
export interface PostV1AnalyticsOverviewStartSurvey {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewStartSurvey
     */
    surveyId: number;
    /**
     * Учитывать роль пользователя?
     * @type {boolean}
     * @memberof PostV1AnalyticsOverviewStartSurvey
     */
    considerRole?: boolean;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewStartSurvey
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewStartSurvey
     */
    filters?: object;
}
/**
 * POST /v1/analytics/overview/start-survey-list
 * @export
 * @interface PostV1AnalyticsOverviewStartSurveyList
 */
export interface PostV1AnalyticsOverviewStartSurveyList {
    /**
     * ID Опроса
     * @type {number}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    surveyId: number;
    /**
     * Критерий поиска (минимум 1 символ, иначе не учитывается)
     * @type {string}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    query?: string;
    /**
     * Фильтр по статусу пар: pending, running, done
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    withStatus?: Array<string>;
    /**
     * Поле сортировки: count_rejected, volume
     * @type {string}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    orderBy?: string;
    /**
     * Направление для сортировки: ASC, DESC
     * @type {string}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    direction?: string;
    /**
     * Выбранная страница
     * @type {number}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    page?: number;
    /**
     * Количество элементов на страницу
     * @type {number}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    limit?: number;
    /**
     * UUID подразделений для фильтрации
     * @type {Array<string>}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    teams?: Array<string>;
    /**
     * Где ключ это тип фильтра, а значение это массив ИДов филтров
     * @type {object}
     * @memberof PostV1AnalyticsOverviewStartSurveyList
     */
    filters?: object;
}
/**
 * POST /v1/couples
 * @export
 * @interface PostV1Couples
 */
export interface PostV1Couples {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof PostV1Couples
     */
    expertListId: number;
    /**
     * ID сотрудников
     * @type {Array<string>}
     * @memberof PostV1Couples
     */
    userIds: Array<string>;
    /**
     * В качестве кого выступает current_user
     * @type {string}
     * @memberof PostV1Couples
     */
    iam: PostV1CouplesIam;
}

/**
    * @export
    * @enum {string}
    */
export enum PostV1CouplesIam {
    EXPERT = 'expert',
    SURVEYEE = 'surveyee'
}

/**
 * POST /v1/couples/by_batch
 * @export
 * @interface PostV1CouplesByBatch
 */
export interface PostV1CouplesByBatch {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof PostV1CouplesByBatch
     */
    expertListId: number;
    /**
     * Участники пар
     * @type {Array<PostV1CouplesByBatchCouples>}
     * @memberof PostV1CouplesByBatch
     */
    couples: Array<PostV1CouplesByBatchCouples>;
}
/**
 * 
 * @export
 * @interface PostV1CouplesByBatchCouples
 */
export interface PostV1CouplesByBatchCouples {
    /**
     * ID пользователя-эксперта
     * @type {string}
     * @memberof PostV1CouplesByBatchCouples
     */
    expertUserId: string;
    /**
     * ID оценивамеого пользователя
     * @type {string}
     * @memberof PostV1CouplesByBatchCouples
     */
    surveyeeUserId: string;
}
/**
 * POST /v1/couples/:couple_id/assessment/answer
 * @export
 * @interface PostV1CouplesCoupleIdAssessmentAnswer
 */
export interface PostV1CouplesCoupleIdAssessmentAnswer {
    /**
     * ID пары
     * @type {string}
     * @memberof PostV1CouplesCoupleIdAssessmentAnswer
     */
    questionUuid: string;
    /**
     * Текущее unix-время в мс
     * @type {number}
     * @memberof PostV1CouplesCoupleIdAssessmentAnswer
     */
    timestamp?: number;
    /**
     * Массив UUID ответов
     * @type {Array<string>}
     * @memberof PostV1CouplesCoupleIdAssessmentAnswer
     */
    answerIds?: Array<string>;
    /**
     * Комментарий
     * @type {string}
     * @memberof PostV1CouplesCoupleIdAssessmentAnswer
     */
    comment?: string;
}
/**
 * POST /v1/couples/:couple_id/move-to-expert-list
 * @export
 * @interface PostV1CouplesCoupleIdMoveToExpertList
 */
export interface PostV1CouplesCoupleIdMoveToExpertList {
    /**
     * ID группы назначения
     * @type {number}
     * @memberof PostV1CouplesCoupleIdMoveToExpertList
     */
    expertListId: number;
}
/**
 * POST /v1/couples/:couple_id/rejected
 * @export
 * @interface PostV1CouplesCoupleIdRejected
 */
export interface PostV1CouplesCoupleIdRejected {
    /**
     * Комментарий для отказа
     * @type {string}
     * @memberof PostV1CouplesCoupleIdRejected
     */
    comment?: string;
}
/**
 * POST /v1/couples/favorite
 * @export
 * @interface PostV1CouplesFavorite
 */
export interface PostV1CouplesFavorite {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1CouplesFavorite
     */
    surveyId: number;
    /**
     * ID пар
     * @type {Array<number>}
     * @memberof PostV1CouplesFavorite
     */
    coupleIds: Array<number>;
}
/**
 * POST /v1/invitations
 * @export
 * @interface PostV1Invitations
 */
export interface PostV1Invitations {
    /**
     * Токен приглашения
     * @type {string}
     * @memberof PostV1Invitations
     */
    token: string;
}
/**
 * POST /v1/invitees
 * @export
 * @interface PostV1Invitees
 */
export interface PostV1Invitees {
    /**
     * Почта внешнего эксперта, поиск по полному вхождению, регистронезависимый
     * @type {string}
     * @memberof PostV1Invitees
     */
    email: string;
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof PostV1Invitees
     */
    expertListId: number;
    /**
     * Фамилия внешнего эксперта
     * @type {string}
     * @memberof PostV1Invitees
     */
    lastName: string;
    /**
     * Имя внешнего эксперта
     * @type {string}
     * @memberof PostV1Invitees
     */
    firstName: string;
    /**
     * ID оцениваемого (иначе current_user из JWT токена)
     * @type {string}
     * @memberof PostV1Invitees
     */
    userId?: string;
}
/**
 * POST /v1/yandex_gpt/weakness
 * @export
 * @interface PostV1YandexGptWeakness
 */
export interface PostV1YandexGptWeakness {
    /**
     * ID опроса
     * @type {number}
     * @memberof PostV1YandexGptWeakness
     */
    surveyId: number;
    /**
     * ID пользователя
     * @type {string}
     * @memberof PostV1YandexGptWeakness
     */
    userId?: string;
    /**
     * Генерация нового отчета
     * @type {boolean}
     * @memberof PostV1YandexGptWeakness
     */
    newGeneration?: boolean;
    /**
     * Повторная генерация в случае ошибки
     * @type {boolean}
     * @memberof PostV1YandexGptWeakness
     */
    retry?: boolean;
}
/**
 * PUT /v1/analytics/feedback/development_tasks/:id
 * @export
 * @interface PutV1AnalyticsFeedbackDevelopmentTasksId
 */
export interface PutV1AnalyticsFeedbackDevelopmentTasksId {
    /**
     * Текст задания
     * @type {string}
     * @memberof PutV1AnalyticsFeedbackDevelopmentTasksId
     */
    name?: string;
    /**
     * Планируемая дата завершения (ISO8601)
     * @type {string}
     * @memberof PutV1AnalyticsFeedbackDevelopmentTasksId
     */
    scheduledAt?: string;
    /**
     * ID вопроса (компетенция)
     * @type {number}
     * @memberof PutV1AnalyticsFeedbackDevelopmentTasksId
     */
    questionId?: number;
    /**
     * Прогресс выполнения
     * @type {number}
     * @memberof PutV1AnalyticsFeedbackDevelopmentTasksId
     */
    progress?: number;
}
/**
 * V1::Entities::Analytics::Feedback::AverageRate model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackAverageRate
 */
export interface V1EntitiesAnalyticsFeedbackAverageRate {
    /**
     * Количество экспертов
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRate
     */
    count: number;
    /**
     * Средняя оценка
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRate
     */
    avg: number;
    /**
     * Бенчмарк
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRate
     */
    bench: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackAverageRateSettings}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRate
     */
    settings: V1EntitiesAnalyticsFeedbackAverageRateSettings;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRate
     */
    optionsAvg: V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg
 */
export interface V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg {
    /**
     * Отображение значения
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg
     */
    display: boolean;
    /**
     * Порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRateOptionsAvg
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackAverageRateSettings
 */
export interface V1EntitiesAnalyticsFeedbackAverageRateSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRateSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRateSettings
     */
    maxValue: number;
    /**
     * Отображение бенчмарка
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackAverageRateSettings
     */
    displayBenches: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::BehaviorQuestionsAnswers model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswers
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswers {
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer>}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswers
     */
    answers: Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer {
    /**
     * Текст варианта ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
     */
    name: string;
    /**
     * Вопрос
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
     */
    questionName: string;
    /**
     * ID Вопроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
     */
    questionId: number;
    /**
     * процент выбора варианта ответа в формате: \".77\"
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
     */
    percent: string;
    /**
     * Кол-во экспертов выбравших этот вариант ответа
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswersAnswer
     */
    count: number;
}
/**
 * V1::Entities::Analytics::Feedback::BehaviorQuestionsDynamics model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics {
    /**
     * Даты
     * @type {Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis>}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics
     */
    axis: Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis>;
    /**
     * Легенды
     * @type {Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics
     */
    legend: Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend>;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue>}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics
     */
    values: Array<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis {
    /**
     * ID ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
     */
    id: string;
    /**
     * Ответ
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
     */
    name: string;
    /**
     * Вопрос
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
     */
    questionName: string;
    /**
     * ID Вопроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
     */
    questionId: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackOptionsValue}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsAxis
     */
    options: V1EntitiesAnalyticsFeedbackOptionsValue;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend {
    /**
     * ID легенды (таймштамп)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend
     */
    id: number;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsLegend
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue
 */
export interface V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue {
    /**
     * ID легенды (таймштамп)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue
     */
    legendId: number;
    /**
     * ID ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue
     */
    axisId: string;
    /**
     * Значение в формате: \".77\"
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue
     */
    value: string;
    /**
     * Подсказка
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamicsValue
     */
    hint: string;
}
/**
 * V1::Entities::Analytics::Feedback::DestructorsDynamics model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsDynamics
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsDynamics {
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamics
     */
    settings: V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings;
    /**
     * Легенды
     * @type {Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamics
     */
    legend: Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend>;
    /**
     * Оси
     * @type {Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis>}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamics
     */
    axis: Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis>;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue>}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamics
     */
    values: Array<V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis {
    /**
     * Таймштамп для связи со значениями через axis_id
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis
     */
    id: string;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsAxis
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend {
    /**
     * ID легенды
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend
     */
    id: number;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsLegend
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings {
    /**
     * Минимальное значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings
     */
    minValue: number;
    /**
     * Максимальное значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsSettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue {
    /**
     * ID легенды
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue
     */
    legendId: number;
    /**
     * ID оси
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue
     */
    axisId: string;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue
     */
    value: number;
    /**
     * Подсказка
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsDynamicsValue
     */
    hint: string;
}
/**
 * V1::Entities::Analytics::Feedback::DestructorsSurvey model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsSurvey
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsSurvey {
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackDestructorsSurveySettings}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurvey
     */
    settings: V1EntitiesAnalyticsFeedbackDestructorsSurveySettings;
    /**
     * Деструкторы
     * @type {Array<V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis>}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurvey
     */
    axis: Array<V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis>;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackDestructorsSurveyValue>}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurvey
     */
    values: Array<V1EntitiesAnalyticsFeedbackDestructorsSurveyValue>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackDestructorsSurveyState}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurvey
     */
    state: V1EntitiesAnalyticsFeedbackDestructorsSurveyState;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis {
    /**
     * ID деструктора
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis
     */
    id: string;
    /**
     * Деструктор
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis
     */
    name: string;
    /**
     * Описание дестуктора
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsSurveySettings
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsSurveySettings {
    /**
     * минимальное значание
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveySettings
     */
    minValue: number;
    /**
     * максимальное значание
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveySettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsSurveyState
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsSurveyState {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyState
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyState
     */
    anonymityThresholdReached: boolean;
    /**
     * Отключать фильтр групп?
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyState
     */
    disableGroupIdFiltering: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDestructorsSurveyValue
 */
export interface V1EntitiesAnalyticsFeedbackDestructorsSurveyValue {
    /**
     * ID деструктора
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyValue
     */
    axisId: string;
    /**
     * значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyValue
     */
    value: number;
    /**
     * строка в формате: значение (в процентах%)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyValue
     */
    hint: string;
    /**
     * Проценты
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDestructorsSurveyValue
     */
    percTotal: number;
}
/**
 * V1::Entities::Analytics::Feedback::DevelopmentTasksIndex model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDevelopmentTasksIndex
 */
export interface V1EntitiesAnalyticsFeedbackDevelopmentTasksIndex {
    /**
     * Список задач
     * @type {Array<V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem>}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndex
     */
    developmentTasks: Array<V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
 */
export interface V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem {
    /**
     * ID задания
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    id: number;
    /**
     * Текст задания
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    name: string;
    /**
     * Вес задания
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    weight: number;
    /**
     * Прогресс выполнения
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    progress: number;
    /**
     * Статус задания, варианты: init, in_progress, finished
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    status: string;
    /**
     * ИД вопроса (компетенция)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    questionId?: number;
    /**
     * Текст вопроса (компетенция)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    questionName?: string;
    /**
     * Планируемая дата завершения (ISO8601)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    scheduledAt?: number;
    /**
     * Фактическая дата завершения (ISO8601)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackDevelopmentTasksIndexDevelopmentTaskItem
     */
    finishedAt?: number;
}
/**
 * V1::Entities::Analytics::Feedback::ExtraQuestionsDynamics model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics {
    /**
     * Легенды
     * @type {Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics
     */
    legend: Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend>;
    /**
     * Динамика
     * @type {Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic>}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics
     */
    dynamics: Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic {
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic
     */
    name: string;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue>}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamic
     */
    values: Array<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue {
    /**
     * ID легенды
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue
     */
    legendId: number;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsDynamicValue
     */
    value: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend {
    /**
     * ID легенды
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend
     */
    id: number;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsDynamicsLegend
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Feedback::ExtraQuestionsScales model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsScales
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsScales {
    /**
     * Вопросы
     * @type {Array<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion>}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScales
     */
    questions: Array<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScales
     */
    settings: V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScales
     */
    state: V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
     */
    id: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
     */
    name: string;
    /**
     * Среднее
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
     */
    average: number;
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer>}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
     */
    answers: Array<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackOptionsValue}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion
     */
    options: V1EntitiesAnalyticsFeedbackOptionsValue;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer {
    /**
     * UUID ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer
     */
    id: string;
    /**
     * Название ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer
     */
    name: string;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer
     */
    value: number;
    /**
     * Количество
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestionAnswer
     */
    count: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesSettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState
 */
export interface V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState
     */
    anonymityThresholdReached: boolean;
    /**
     * Отключать фильтр групп?
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackExtraQuestionsScalesState
     */
    disableGroupIdFiltering: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::GeneralQuestionsAnswers model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers {
    /**
     * Группы
     * @type {Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers
     */
    groups: Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup>;
    /**
     * Вопросы
     * @type {Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers
     */
    questions: Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion>;
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers
     */
    answers: Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer {
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer
     */
    questionId: string;
    /**
     * Текст ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersAnswer
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup {
    /**
     * ID группы вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup
     */
    id: string;
    /**
     * Название группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersGroup
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion {
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion
     */
    id: string;
    /**
     * ID группы вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion
     */
    questionGroupId: string;
    /**
     * Текст вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswersQuestion
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Feedback::GeneralQuestionsDynamicsByCompetentions model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions {
    /**
     * Легенда
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
     */
    legend: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>;
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions
     */
    dynamics: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>;
}
/**
 * V1::Entities::Analytics::Feedback::GeneralQuestionsDynamicsByIndicators model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators {
    /**
     * Легенда
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
     */
    legend: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>;
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators
     */
    dynamics: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>;
}
/**
 * V1::Entities::Analytics::Feedback::GeneralQuestionsTop model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTop
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTop {
    /**
     * Список элементов
     * @type {Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem>}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTop
     */
    items: Array<V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTop
     */
    state: V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem {
    /**
     * Тип (strong/weak)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
     */
    type: string;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
     */
    value: number;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
     */
    groupTitle: string;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
     */
    title: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject
 */
export interface V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackGeneralQuestionsTopStateObject
     */
    anonymityThresholdReached: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::Johari model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohari
 */
export interface V1EntitiesAnalyticsFeedbackJohari {
    /**
     * Список элементов
     * @type {Array<V1EntitiesAnalyticsFeedbackJohariItem>}
     * @memberof V1EntitiesAnalyticsFeedbackJohari
     */
    items: Array<V1EntitiesAnalyticsFeedbackJohariItem>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackJohariStateObject}
     * @memberof V1EntitiesAnalyticsFeedbackJohari
     */
    state: V1EntitiesAnalyticsFeedbackJohariStateObject;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackJohariSettings}
     * @memberof V1EntitiesAnalyticsFeedbackJohari
     */
    settings: V1EntitiesAnalyticsFeedbackJohariSettings;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariItem
 */
export interface V1EntitiesAnalyticsFeedbackJohariItem {
    /**
     * ID
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackJohariItem
     */
    coordinateId: string;
    /**
     * Количество
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariItem
     */
    count: number;
    /**
     * Самооценка
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariItem
     */
    self: number;
    /**
     * Средняя оценка
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariItem
     */
    avg: number;
}
/**
 * V1::Entities::Analytics::Feedback::JohariQuestions model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariQuestions
 */
export interface V1EntitiesAnalyticsFeedbackJohariQuestions {
    /**
     * Список элементов
     * @type {Array<V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion>}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestions
     */
    questions: Array<V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestions
     */
    state: V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackJohariQuestionsSettings}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestions
     */
    settings: V1EntitiesAnalyticsFeedbackJohariQuestionsSettings;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
 */
export interface V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion {
    /**
     * ID
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
     */
    coordinateId: string;
    /**
     * Самооценка
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
     */
    selfRating: number;
    /**
     * Средняя оценка
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
     */
    averageRating: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
     */
    name: string;
    /**
     * Название группы вопросов
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion
     */
    groupQuestionName: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariQuestionsSettings
 */
export interface V1EntitiesAnalyticsFeedbackJohariQuestionsSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsSettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject
 */
export interface V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackJohariQuestionsStateObject
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariSettings
 */
export interface V1EntitiesAnalyticsFeedbackJohariSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackJohariSettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackJohariStateObject
 */
export interface V1EntitiesAnalyticsFeedbackJohariStateObject {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackJohariStateObject
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackJohariStateObject
     */
    anonymityThresholdReached: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::NpsQuestionsDynamicsByCompetentions model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
 */
export interface V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions {
    /**
     * Легенда
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
     */
    legend: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>;
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>}
     * @memberof V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
     */
    dynamics: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>;
}
/**
 * V1::Entities::Analytics::Feedback::NpsQuestionsDynamicsByIndicators model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators
 */
export interface V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators {
    /**
     * Легенда
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>}
     * @memberof V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators
     */
    legend: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend>;
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>}
     * @memberof V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators
     */
    dynamics: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic>;
}
/**
 * V1::Entities::Analytics::Feedback::OpenQuestionAnswers model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswers
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswers {
    /**
     * Вопросы
     * @type {Array<V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion>}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswers
     */
    questions: Array<V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion>;
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer>}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswers
     */
    answers: Array<V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswers
     */
    state: V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer {
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer
     */
    questionId: string;
    /**
     * Текст ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion {
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion
     */
    id: string;
    /**
     * Текст вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion
     */
    name: string;
    /**
     * Количество комментов
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion
     */
    count: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState
     */
    anonymityThresholdReached: boolean;
    /**
     * Отключать фильтр групп?
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionAnswersState
     */
    disableGroupIdFiltering: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::OpenQuestionListComments model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionListComments
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionListComments {
    /**
     * Вопросы
     * @type {Array<V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion>}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListComments
     */
    questions: Array<V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion>;
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer>}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListComments
     */
    answers: Array<V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackState}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListComments
     */
    state: V1EntitiesAnalyticsFeedbackState;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer {
    /**
     * Дата
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer
     */
    date: string;
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer
     */
    questionId: string;
    /**
     * Текст ответа
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion {
    /**
     * ID вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion
     */
    id: string;
    /**
     * Текст вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Feedback::OpenQuestionTopComments model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionTopComments
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionTopComments {
    /**
     * Ответы
     * @type {Array<V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment>}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionTopComments
     */
    comments: Array<V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment
 */
export interface V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment {
    /**
     * Дата
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment
     */
    date: string;
    /**
     * Вопрос
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment
     */
    question: string;
    /**
     * Ответ
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment
     */
    answer: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackOptionsValue
 */
export interface V1EntitiesAnalyticsFeedbackOptionsValue {
    /**
     * Отображение значения
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackOptionsValue
     */
    display: boolean;
    /**
     * Порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackOptionsValue
     */
    anonymityThresholdReached: boolean;
}
/**
 * V1::Entities::Analytics::Feedback::QuestionsComment model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsComment
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsComment {
    /**
     * Группы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsCommentGroup>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsComment
     */
    groups: Array<V1EntitiesAnalyticsFeedbackQuestionsCommentGroup>;
    /**
     * Комментарии
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsCommentComment>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsComment
     */
    comments: Array<V1EntitiesAnalyticsFeedbackQuestionsCommentComment>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsCommentState}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsComment
     */
    state: V1EntitiesAnalyticsFeedbackQuestionsCommentState;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsCommentComment
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsCommentComment {
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentComment
     */
    groupId: number;
    /**
     * Текст комментария
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentComment
     */
    text: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsCommentGroup
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsCommentGroup {
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentGroup
     */
    id: number;
    /**
     * Название группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentGroup
     */
    name: string;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentGroup
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsCommentState
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsCommentState {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentState
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentState
     */
    anonymityThresholdReached: boolean;
    /**
     * Отключать фильтр групп?
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsCommentState
     */
    disableGroupIdFiltering: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic {
    /**
     * Дата (iso8601)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic
     */
    name: string;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamic
     */
    values: Array<V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue {
    /**
     * ID легенды
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue
     */
    legendId: string;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsDynamicValue
     */
    value: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend {
    /**
     * id компетенции/индикатора
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend
     */
    id: string;
    /**
     * Название компетенции/индикатора
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Feedback::QuestionsTemp model
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTemp
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTemp {
    /**
     * Группы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsTempGroup>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTemp
     */
    groups: Array<V1EntitiesAnalyticsFeedbackQuestionsTempGroup>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsTempStateObject}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTemp
     */
    state: V1EntitiesAnalyticsFeedbackQuestionsTempStateObject;
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsTempItem>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTemp
     */
    items: Array<V1EntitiesAnalyticsFeedbackQuestionsTempItem>;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsTempSettings}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTemp
     */
    settings: V1EntitiesAnalyticsFeedbackQuestionsTempSettings;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempGroup
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempGroup {
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempGroup
     */
    id: number;
    /**
     * Название группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempGroup
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempItem
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempItem {
    /**
     * ID вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    id: string;
    /**
     * ID вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    parentId: string;
    /**
     * Название вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    name: string;
    /**
     * Количество экспертов
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    experts: number;
    /**
     * Процент отклоненных (float)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    declined: number;
    /**
     * Среднее
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    average: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    averageOptions: V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue;
    /**
     * Бенчмарк
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    bench: number;
    /**
     * индекс для сорировки
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    sortIndex: number;
    /**
     * Количество комментариев (может быть null)
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    comments: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    commentsOptions: V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsFeedbackQuestionsTempItemValue>}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItem
     */
    values: Array<V1EntitiesAnalyticsFeedbackQuestionsTempItemValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempItemValue
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempItemValue {
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItemValue
     */
    groupId: number;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItemValue
     */
    value?: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempItemValue
     */
    options: V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue {
    /**
     * Отображение значения
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue
     */
    display: boolean;
    /**
     * Порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempOptionsValue
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempSettings
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempSettings
     */
    maxValue: number;
    /**
     * Отображение бенчмарка
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempSettings
     */
    displayBenches: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackQuestionsTempStateObject
 */
export interface V1EntitiesAnalyticsFeedbackQuestionsTempStateObject {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempStateObject
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackQuestionsTempStateObject
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFeedbackState
 */
export interface V1EntitiesAnalyticsFeedbackState {
    /**
     * Статус
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackState
     */
    status: boolean;
    /**
     * Достигнут порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsFeedbackState
     */
    anonymityThresholdReached: boolean;
}
/**
 * V1::Entities::Analytics::Filters::List model
 * @export
 * @interface V1EntitiesAnalyticsFiltersList
 */
export interface V1EntitiesAnalyticsFiltersList {
    /**
     * Фильтры сотрудника
     * @type {Array<V1EntitiesAnalyticsFiltersListFilters>}
     * @memberof V1EntitiesAnalyticsFiltersList
     */
    filters: Array<V1EntitiesAnalyticsFiltersListFilters>;
    /**
     * Подразделения
     * @type {Array<V1EntitiesAnalyticsFiltersListTeam>}
     * @memberof V1EntitiesAnalyticsFiltersList
     */
    teams: Array<V1EntitiesAnalyticsFiltersListTeam>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFiltersListFilters
 */
export interface V1EntitiesAnalyticsFiltersListFilters {
    /**
     * Ключ фильтра
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListFilters
     */
    metakey: string;
    /**
     * Название фильтра
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListFilters
     */
    metakeyI18n: string;
    /**
     * Значения фильтра
     * @type {Array<V1EntitiesAnalyticsFiltersListMetaValue>}
     * @memberof V1EntitiesAnalyticsFiltersListFilters
     */
    values: Array<V1EntitiesAnalyticsFiltersListMetaValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFiltersListMetaValue
 */
export interface V1EntitiesAnalyticsFiltersListMetaValue {
    /**
     * ИД
     * @type {number}
     * @memberof V1EntitiesAnalyticsFiltersListMetaValue
     */
    id: number;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListMetaValue
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsFiltersListTeam
 */
export interface V1EntitiesAnalyticsFiltersListTeam {
    /**
     * ИД подразделения (uuid)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListTeam
     */
    id: string;
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListTeam
     */
    name: string;
    /**
     * ИД родителя (uuid)
     * @type {string}
     * @memberof V1EntitiesAnalyticsFiltersListTeam
     */
    parentId: string;
}
/**
 * V1::Entities::Analytics::Management::Audiences model
 * @export
 * @interface V1EntitiesAnalyticsManagementAudiences
 */
export interface V1EntitiesAnalyticsManagementAudiences {
    /**
     * Список участников
     * @type {Array<V1EntitiesAnalyticsManagementAudiencesAudience>}
     * @memberof V1EntitiesAnalyticsManagementAudiences
     */
    audiences: Array<V1EntitiesAnalyticsManagementAudiencesAudience>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAnalyticsManagementAudiences
     */
    pagination: V1EntitiesPaginateOutput;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementAudiencesAudience
 */
export interface V1EntitiesAnalyticsManagementAudiencesAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementAudiencesAudience
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::Analytics::Management::Dashboards model
 * @export
 * @interface V1EntitiesAnalyticsManagementDashboards
 */
export interface V1EntitiesAnalyticsManagementDashboards {
    /**
     * Список участников
     * @type {Array<V1EntitiesAnalyticsManagementDashboardsAudience>}
     * @memberof V1EntitiesAnalyticsManagementDashboards
     */
    audiences: Array<V1EntitiesAnalyticsManagementDashboardsAudience>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAnalyticsManagementDashboards
     */
    pagination: V1EntitiesPaginateOutput;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDashboardsAudience
 */
export interface V1EntitiesAnalyticsManagementDashboardsAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDashboardsAudience
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::Analytics::Management::DifferenceHeatmap model
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmap
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmap {
    /**
     * Элементы
     * @type {Array<V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion>}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmap
     */
    questions: Array<V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion>;
    /**
     * Пользователи
     * @type {Array<V1EntitiesAnalyticsManagementDifferenceHeatmapUser>}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmap
     */
    users: Array<V1EntitiesAnalyticsManagementDifferenceHeatmapUser>;
    /**
     * 
     * @type {V1EntitiesAnalyticsManagementDifferenceHeatmapSettings}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmap
     */
    settings: V1EntitiesAnalyticsManagementDifferenceHeatmapSettings;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion {
    /**
     * ID вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion
     */
    id: string;
    /**
     * ID вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion
     */
    parentId: string;
    /**
     * Название вопроса / группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion
     */
    name: string;
    /**
     * Значения
     * @type {Array<V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue>}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion
     */
    values: Array<V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue {
    /**
     * Отображение значения
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue
     */
    display: boolean;
    /**
     * Порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue {
    /**
     * ID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue
     */
    userId: string;
    /**
     * Значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue
     */
    value: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionValue
     */
    options: V1EntitiesAnalyticsManagementDifferenceHeatmapQuestionOptionsValue;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmapSettings
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmapSettings {
    /**
     * Миниальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapSettings
     */
    minValue: number;
    /**
     * Максимальное значение шкалы
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapSettings
     */
    maxValue: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementDifferenceHeatmapUser
 */
export interface V1EntitiesAnalyticsManagementDifferenceHeatmapUser {
    /**
     * ID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapUser
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementDifferenceHeatmapUser
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Management::ExpertListBySurvey model
 * @export
 * @interface V1EntitiesAnalyticsManagementExpertListBySurvey
 */
export interface V1EntitiesAnalyticsManagementExpertListBySurvey {
    /**
     * Список групп экпертов
     * @type {Array<V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList>}
     * @memberof V1EntitiesAnalyticsManagementExpertListBySurvey
     */
    expertLists: Array<V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList
 */
export interface V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList {
    /**
     * ID группы экпертов
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList
     */
    id: number;
    /**
     * Название группы экпертов (со стороны оцениваемого)
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Management::ResultForUsers model
 * @export
 * @interface V1EntitiesAnalyticsManagementResultForUsers
 */
export interface V1EntitiesAnalyticsManagementResultForUsers {
    /**
     * Список участников
     * @type {Array<V1EntitiesAnalyticsManagementResultForUsersAudience>}
     * @memberof V1EntitiesAnalyticsManagementResultForUsers
     */
    users: Array<V1EntitiesAnalyticsManagementResultForUsersAudience>;
    /**
     * Список результатов
     * @type {Array<V1EntitiesAnalyticsManagementResultForUsersItem>}
     * @memberof V1EntitiesAnalyticsManagementResultForUsers
     */
    result: Array<V1EntitiesAnalyticsManagementResultForUsersItem>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAnalyticsManagementResultForUsers
     */
    pagination: V1EntitiesPaginateOutput;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementResultForUsersAudience
 */
export interface V1EntitiesAnalyticsManagementResultForUsersAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementResultForUsersItem
 */
export interface V1EntitiesAnalyticsManagementResultForUsersItem {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    userId: string;
    /**
     * ID опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    surveyId: string;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    surveyName: string;
    /**
     * Среднее значение (deprecated)
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    benchAvg?: number;
    /**
     * Среднее значение
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    average?: number;
    /**
     * 
     * @type {V1EntitiesAnalyticsManagementResultForUsersOptionsValue}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    averageOptions: V1EntitiesAnalyticsManagementResultForUsersOptionsValue;
    /**
     * Прогресс ИПР
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    developmentTasksProgress?: number;
    /**
     * Валидность весов ИПР
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    developmentTasksInvalidWeights?: boolean;
    /**
     * 
     * @type {V1EntitiesAnalyticsManagementResultForUsersSettingsScale}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersItem
     */
    settings: V1EntitiesAnalyticsManagementResultForUsersSettingsScale;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementResultForUsersOptionsValue
 */
export interface V1EntitiesAnalyticsManagementResultForUsersOptionsValue {
    /**
     * Отображение значения
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersOptionsValue
     */
    display: boolean;
    /**
     * Порог анонимности
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersOptionsValue
     */
    anonymityThresholdReached: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementResultForUsersSettingsScale
 */
export interface V1EntitiesAnalyticsManagementResultForUsersSettingsScale {
    /**
     * Минимальная шкала
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersSettingsScale
     */
    minValue?: number;
    /**
     * Максимальная шкала
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementResultForUsersSettingsScale
     */
    maxValue?: number;
}
/**
 * V1::Entities::Analytics::Management::SubordinateByIds model
 * @export
 * @interface V1EntitiesAnalyticsManagementSubordinateByIds
 */
export interface V1EntitiesAnalyticsManagementSubordinateByIds {
    /**
     * Список пользователей
     * @type {Array<V1EntitiesAnalyticsManagementSubordinateByIdsUser>}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIds
     */
    users: Array<V1EntitiesAnalyticsManagementSubordinateByIdsUser>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementSubordinateByIdsUser
 */
export interface V1EntitiesAnalyticsManagementSubordinateByIdsUser {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSubordinateByIdsUser
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::Analytics::Management::Surveys model
 * @export
 * @interface V1EntitiesAnalyticsManagementSurveys
 */
export interface V1EntitiesAnalyticsManagementSurveys {
    /**
     * Public список опросов
     * @type {Array<V1EntitiesAnalyticsManagementSurveysSurvey>}
     * @memberof V1EntitiesAnalyticsManagementSurveys
     */
    surveys: Array<V1EntitiesAnalyticsManagementSurveysSurvey>;
}
/**
 * V1::Entities::Analytics::Management::SurveysForUsers model
 * @export
 * @interface V1EntitiesAnalyticsManagementSurveysForUsers
 */
export interface V1EntitiesAnalyticsManagementSurveysForUsers {
    /**
     * Public список опросов
     * @type {Array<V1EntitiesAnalyticsManagementSurveysForUsersSurvey>}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsers
     */
    surveys: Array<V1EntitiesAnalyticsManagementSurveysForUsersSurvey>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementSurveysForUsersSurvey
 */
export interface V1EntitiesAnalyticsManagementSurveysForUsersSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    name: string;
    /**
     * UUID картинки
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    image: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    description: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    dateEnd: string;
    /**
     * Есть средний бал
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementSurveysForUsersSurvey
     */
    withBenchAvg: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsManagementSurveysSurvey
 */
export interface V1EntitiesAnalyticsManagementSurveysSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    name: string;
    /**
     * UUID картинки
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    image: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    description: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    dateEnd: string;
    /**
     * Есть средний бал
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsManagementSurveysSurvey
     */
    withBenchAvg: boolean;
}
/**
 * V1::Entities::Analytics::Overview::ChooseExperts model
 * @export
 * @interface V1EntitiesAnalyticsOverviewChooseExperts
 */
export interface V1EntitiesAnalyticsOverviewChooseExperts {
    /**
     * Константа лимита кол-ва экпертов (меньше или больше чего)
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewChooseExperts
     */
    expertLimit: number;
    /**
     * Больше или равно N
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewChooseExperts
     */
    more: number;
    /**
     * Меньше N
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewChooseExperts
     */
    less: number;
}
/**
 * V1::Entities::Analytics::Overview::ChooseExpertsList model
 * @export
 * @interface V1EntitiesAnalyticsOverviewChooseExpertsList
 */
export interface V1EntitiesAnalyticsOverviewChooseExpertsList {
    /**
     * Рубикон, отностительно чего меньше или больше выбранно экспертов
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsList
     */
    expertLimit: number;
    /**
     * Список участников
     * @type {Array<V1EntitiesAnalyticsOverviewChooseExpertsListAudience>}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsList
     */
    audiences: Array<V1EntitiesAnalyticsOverviewChooseExpertsListAudience>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsList
     */
    pagination: V1EntitiesPaginateOutput;
    /**
     * Флаг, указывающий, есть ли доступные методы уведомления для аудитории опрса
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsList
     */
    notificationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsOverviewChooseExpertsListAudience
 */
export interface V1EntitiesAnalyticsOverviewChooseExpertsListAudience {
    /**
     * ID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    userId: string;
    /**
     * Имя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    firstName: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    lastName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    sex: string;
    /**
     * Главная ШД
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    teamsWithDelimiter: string;
    /**
     * Сколько экспертов выбрал сотрудник
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewChooseExpertsListAudience
     */
    expertCount: number;
}
/**
 * V1::Entities::Analytics::Overview::StartSurvey model
 * @export
 * @interface V1EntitiesAnalyticsOverviewStartSurvey
 */
export interface V1EntitiesAnalyticsOverviewStartSurvey {
    /**
     * Не начали
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurvey
     */
    pending: number;
    /**
     * В процессе
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurvey
     */
    running: number;
    /**
     * Завершили
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurvey
     */
    done: number;
}
/**
 * V1::Entities::Analytics::Overview::StartSurveyList model
 * @export
 * @interface V1EntitiesAnalyticsOverviewStartSurveyList
 */
export interface V1EntitiesAnalyticsOverviewStartSurveyList {
    /**
     * Список участников
     * @type {Array<V1EntitiesAnalyticsOverviewStartSurveyListAudience>}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyList
     */
    audiences: Array<V1EntitiesAnalyticsOverviewStartSurveyListAudience>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyList
     */
    pagination: V1EntitiesPaginateOutput;
    /**
     * Флаг, указывающий, есть ли доступные методы уведомления для аудитории опрса
     * @type {boolean}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyList
     */
    notificationAvailable: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsOverviewStartSurveyListAudience
 */
export interface V1EntitiesAnalyticsOverviewStartSurveyListAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    staffPositionMain: string;
    /**
     * 
     * @type {V1EntitiesAnalyticsOverviewStartSurveyListProgress}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListAudience
     */
    progress: V1EntitiesAnalyticsOverviewStartSurveyListProgress;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsOverviewStartSurveyListProgress
 */
export interface V1EntitiesAnalyticsOverviewStartSurveyListProgress {
    /**
     * Статус: pending, running, done
     * @type {string}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListProgress
     */
    status: string;
    /**
     * Процент прохождения оценки
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListProgress
     */
    volume: number;
    /**
     * Всего (без отмененных)
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListProgress
     */
    total: number;
    /**
     * Завершили
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListProgress
     */
    done: number;
    /**
     * Отказал
     * @type {number}
     * @memberof V1EntitiesAnalyticsOverviewStartSurveyListProgress
     */
    rejected: number;
}
/**
 * V1::Entities::Analytics::Surveys::Pages model
 * @export
 * @interface V1EntitiesAnalyticsSurveysPages
 */
export interface V1EntitiesAnalyticsSurveysPages {
    /**
     * Список страниц
     * @type {Array<V1EntitiesAnalyticsSurveysPagesPage>}
     * @memberof V1EntitiesAnalyticsSurveysPages
     */
    pages: Array<V1EntitiesAnalyticsSurveysPagesPage>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsSurveysPagesPage
 */
export interface V1EntitiesAnalyticsSurveysPagesPage {
    /**
     * Алиас (general, extra, open, destructor, prof, nps)
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPage
     */
    alias: string;
    /**
     * Название страницы
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPage
     */
    name: string;
    /**
     * Группа
     * @type {Array<V1EntitiesAnalyticsSurveysPagesPageGroup>}
     * @memberof V1EntitiesAnalyticsSurveysPagesPage
     */
    groups: Array<V1EntitiesAnalyticsSurveysPagesPageGroup>;
    /**
     * Вопрос
     * @type {Array<V1EntitiesAnalyticsSurveysPagesPageQuestion>}
     * @memberof V1EntitiesAnalyticsSurveysPagesPage
     */
    questions: Array<V1EntitiesAnalyticsSurveysPagesPageQuestion>;
    /**
     * Ответ
     * @type {Array<V1EntitiesAnalyticsSurveysPagesPageAnswer>}
     * @memberof V1EntitiesAnalyticsSurveysPagesPage
     */
    answers: Array<V1EntitiesAnalyticsSurveysPagesPageAnswer>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsSurveysPagesPageAnswer
 */
export interface V1EntitiesAnalyticsSurveysPagesPageAnswer {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageAnswer
     */
    questionId: number;
    /**
     * Название ответа - первая часть
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageAnswer
     */
    summary: string;
    /**
     * Название ответа - вторая часть
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageAnswer
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsSurveysPagesPageGroup
 */
export interface V1EntitiesAnalyticsSurveysPagesPageGroup {
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageGroup
     */
    id: number;
    /**
     * ID родительской группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageGroup
     */
    parentId: number;
    /**
     * Название группы
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageGroup
     */
    name: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsSurveysPagesPageQuestion
 */
export interface V1EntitiesAnalyticsSurveysPagesPageQuestion {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageQuestion
     */
    id: number;
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageQuestion
     */
    groupId: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysPagesPageQuestion
     */
    name: string;
}
/**
 * V1::Entities::Analytics::Surveys::Tabs model
 * @export
 * @interface V1EntitiesAnalyticsSurveysTabs
 */
export interface V1EntitiesAnalyticsSurveysTabs {
    /**
     * Список элементов
     * @type {Array<V1EntitiesAnalyticsSurveysTabsTab>}
     * @memberof V1EntitiesAnalyticsSurveysTabs
     */
    tabs: Array<V1EntitiesAnalyticsSurveysTabsTab>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAnalyticsSurveysTabsTab
 */
export interface V1EntitiesAnalyticsSurveysTabsTab {
    /**
     * Алиас (general, extra, open, destructor, prof, nps)
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysTabsTab
     */
    alias: string;
    /**
     * Название группы вопросов
     * @type {string}
     * @memberof V1EntitiesAnalyticsSurveysTabsTab
     */
    name: string;
}
/**
 * V1::Entities::AssessmentObjects::Public::Demo model
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicDemo
 */
export interface V1EntitiesAssessmentObjectsPublicDemo {
    /**
     * Список групп вопросов
     * @type {Array<V1EntitiesAssessmentObjectsPublicDemoGroup>}
     * @memberof V1EntitiesAssessmentObjectsPublicDemo
     */
    groups: Array<V1EntitiesAssessmentObjectsPublicDemoGroup>;
    /**
     * Список вопросов
     * @type {Array<V1EntitiesAssessmentObjectsPublicDemoQuestion>}
     * @memberof V1EntitiesAssessmentObjectsPublicDemo
     */
    questions: Array<V1EntitiesAssessmentObjectsPublicDemoQuestion>;
    /**
     * 
     * @type {V1EntitiesAssessmentObjectsPublicDemoAudience}
     * @memberof V1EntitiesAssessmentObjectsPublicDemo
     */
    surveyee: V1EntitiesAssessmentObjectsPublicDemoAudience;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicDemoAudience
 */
export interface V1EntitiesAssessmentObjectsPublicDemoAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicDemoGroup
 */
export interface V1EntitiesAssessmentObjectsPublicDemoGroup {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoGroup
     */
    id: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoGroup
     */
    name: string;
    /**
     * ID группы вопросов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoGroup
     */
    parentId: number;
    /**
     * Общая шкала по всей группе вопросов
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoGroup
     */
    overallScale: boolean;
    /**
     * Отображение вопросов
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoGroup
     */
    questionsLayout: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicDemoQuestion
 */
export interface V1EntitiesAssessmentObjectsPublicDemoQuestion {
    /**
     * ID вопроса (внутренний)
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    id: number;
    /**
     * UUID вопроса (внешний)
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    questionUuid: string;
    /**
     * ID группы вопросов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    groupId: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    name: string;
    /**
     * Описание вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    description: string;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    kind: string;
    /**
     * Количество ответов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    answerCount: number;
    /**
     * Требуется комментарий
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    needComment: boolean;
    /**
     * Комментарий обязателен
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    requiredComment: boolean;
    /**
     * Обязательный
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    required: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    comment: string;
    /**
     * Тип
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    type: string;
    /**
     * Список возможных ответов
     * @type {Array<V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer>}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestion
     */
    answers: Array<V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
 */
export interface V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer {
    /**
     * ID ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
     */
    id: string;
    /**
     * Описание ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
     */
    description: string;
    /**
     * Название ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
     */
    value: string;
    /**
     * Иконка
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
     */
    iconName: string;
    /**
     * Выбран пользователем
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicDemoQuestionAnswer
     */
    chosen: boolean;
}
/**
 * V1::Entities::AssessmentObjects::Public::Show model
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicShow
 */
export interface V1EntitiesAssessmentObjectsPublicShow {
    /**
     * Текущее время в миллисекундах
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShow
     */
    timestamp: number;
    /**
     * Список групп вопросов
     * @type {Array<V1EntitiesAssessmentObjectsPublicShowGroup>}
     * @memberof V1EntitiesAssessmentObjectsPublicShow
     */
    groups: Array<V1EntitiesAssessmentObjectsPublicShowGroup>;
    /**
     * Список вопросов
     * @type {Array<V1EntitiesAssessmentObjectsPublicShowQuestion>}
     * @memberof V1EntitiesAssessmentObjectsPublicShow
     */
    questions: Array<V1EntitiesAssessmentObjectsPublicShowQuestion>;
    /**
     * 
     * @type {V1EntitiesAssessmentObjectsPublicShowAudience}
     * @memberof V1EntitiesAssessmentObjectsPublicShow
     */
    surveyee: V1EntitiesAssessmentObjectsPublicShowAudience;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicShowAudience
 */
export interface V1EntitiesAssessmentObjectsPublicShowAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicShowGroup
 */
export interface V1EntitiesAssessmentObjectsPublicShowGroup {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShowGroup
     */
    id: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowGroup
     */
    name: string;
    /**
     * ID группы вопросов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShowGroup
     */
    parentId: number;
    /**
     * Общая шкала по всей группе вопросов
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicShowGroup
     */
    overallScale: boolean;
    /**
     * Отображение вопросов
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowGroup
     */
    questionsLayout: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicShowQuestion
 */
export interface V1EntitiesAssessmentObjectsPublicShowQuestion {
    /**
     * ID вопроса (внутренний)
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    id: number;
    /**
     * UUID вопроса (внешний)
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    questionUuid: string;
    /**
     * ID группы вопросов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    groupId: number;
    /**
     * Название вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    name: string;
    /**
     * Описание вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    description: string;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    kind: string;
    /**
     * Количество ответов
     * @type {number}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    answerCount: number;
    /**
     * Требуется комментарий
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    needComment: boolean;
    /**
     * Комментарий обязателен
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    requiredComment: boolean;
    /**
     * Обязательный
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    required: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    comment: string;
    /**
     * Тип
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    type: string;
    /**
     * Список возможных ответов
     * @type {Array<V1EntitiesAssessmentObjectsPublicShowQuestionAnswer>}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestion
     */
    answers: Array<V1EntitiesAssessmentObjectsPublicShowQuestionAnswer>;
}
/**
 * 
 * @export
 * @interface V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
 */
export interface V1EntitiesAssessmentObjectsPublicShowQuestionAnswer {
    /**
     * ID ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
     */
    id: string;
    /**
     * Описание ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
     */
    description: string;
    /**
     * Название ответа
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
     */
    value: string;
    /**
     * Иконка
     * @type {string}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
     */
    iconName: string;
    /**
     * Выбран пользователем
     * @type {boolean}
     * @memberof V1EntitiesAssessmentObjectsPublicShowQuestionAnswer
     */
    chosen: boolean;
}
/**
 * V1::Entities::Audiences::Public::SearchIndex model
 * @export
 * @interface V1EntitiesAudiencesPublicSearchIndex
 */
export interface V1EntitiesAudiencesPublicSearchIndex {
    /**
     * Список участников
     * @type {Array<V1EntitiesAudiencesPublicSearchIndexAudience>}
     * @memberof V1EntitiesAudiencesPublicSearchIndex
     */
    audiences: Array<V1EntitiesAudiencesPublicSearchIndexAudience>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesAudiencesPublicSearchIndex
     */
    pagination: V1EntitiesPaginateOutput;
}
/**
 * 
 * @export
 * @interface V1EntitiesAudiencesPublicSearchIndexAudience
 */
export interface V1EntitiesAudiencesPublicSearchIndexAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesAudiencesPublicSearchIndexAudience
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::Couples::Public::ExpertIndex model
 * @export
 * @interface V1EntitiesCouplesPublicExpertIndex
 */
export interface V1EntitiesCouplesPublicExpertIndex {
    /**
     * Список групп экспертов
     * @type {Array<V1EntitiesCouplesPublicExpertIndexGroup>}
     * @memberof V1EntitiesCouplesPublicExpertIndex
     */
    groups: Array<V1EntitiesCouplesPublicExpertIndexGroup>;
    /**
     * Список пар
     * @type {Array<V1EntitiesCouplesPublicExpertIndexCouple>}
     * @memberof V1EntitiesCouplesPublicExpertIndex
     */
    couples: Array<V1EntitiesCouplesPublicExpertIndexCouple>;
}
/**
 * 
 * @export
 * @interface V1EntitiesCouplesPublicExpertIndexCouple
 */
export interface V1EntitiesCouplesPublicExpertIndexCouple {
    /**
     * ID пары
     * @type {number}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    id: number;
    /**
     * Статус
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    status: string;
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    groupId: number;
    /**
     * Возможность отказа от опроса
     * @type {boolean}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    canReject: boolean;
    /**
     * Комментарий при отклонении
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    rejectedComment: string;
    /**
     * 
     * @type {V1EntitiesCouplesPublicExpertIndexCoupleAudience}
     * @memberof V1EntitiesCouplesPublicExpertIndexCouple
     */
    surveyee: V1EntitiesCouplesPublicExpertIndexCoupleAudience;
}
/**
 * 
 * @export
 * @interface V1EntitiesCouplesPublicExpertIndexCoupleAudience
 */
export interface V1EntitiesCouplesPublicExpertIndexCoupleAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexCoupleAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesCouplesPublicExpertIndexGroup
 */
export interface V1EntitiesCouplesPublicExpertIndexGroup {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    id: number;
    /**
     * Название группы экспертов
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    name: string;
    /**
     * Описание группы экспертов
     * @type {string}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    description: string;
    /**
     * Добавление опрашиваемых разрешено
     * @type {boolean}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    canAddSurveyee: boolean;
    /**
     * Можно удалять пары?
     * @type {boolean}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    canDeleteCouples: boolean;
    /**
     * Можно перемещать пары в группу?
     * @type {boolean}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    canReceiveCouplesOnMove: boolean;
    /**
     * Добавление внешнего эксперта разрешено
     * @type {boolean}
     * @memberof V1EntitiesCouplesPublicExpertIndexGroup
     */
    canAddExternalExperts: boolean;
}
/**
 * V1::Entities::Couples::Public::MoveByExpert model
 * @export
 * @interface V1EntitiesCouplesPublicMoveByExpert
 */
export interface V1EntitiesCouplesPublicMoveByExpert {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesCouplesPublicMoveByExpert
     */
    expertListId: number;
}
/**
 * V1::Entities::DevelopmentTasks::Create model
 * @export
 * @interface V1EntitiesDevelopmentTasksCreate
 */
export interface V1EntitiesDevelopmentTasksCreate {
    /**
     * ID задания
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksCreate
     */
    id: number;
}
/**
 * V1::Entities::DevelopmentTasks::Progress model
 * @export
 * @interface V1EntitiesDevelopmentTasksProgress
 */
export interface V1EntitiesDevelopmentTasksProgress {
    /**
     * Прогресс ИПР
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksProgress
     */
    developmentTasksProgress?: number;
    /**
     * Валидность весов ИПР
     * @type {boolean}
     * @memberof V1EntitiesDevelopmentTasksProgress
     */
    developmentTasksInvalidWeights?: boolean;
}
/**
 * V1::Entities::DevelopmentTasks::Questions model
 * @export
 * @interface V1EntitiesDevelopmentTasksQuestions
 */
export interface V1EntitiesDevelopmentTasksQuestions {
    /**
     * Список вопросов (компетенции)
     * @type {Array<V1EntitiesDevelopmentTasksQuestionsQuestion>}
     * @memberof V1EntitiesDevelopmentTasksQuestions
     */
    questions: Array<V1EntitiesDevelopmentTasksQuestionsQuestion>;
}
/**
 * 
 * @export
 * @interface V1EntitiesDevelopmentTasksQuestionsQuestion
 */
export interface V1EntitiesDevelopmentTasksQuestionsQuestion {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksQuestionsQuestion
     */
    id: number;
    /**
     * Текст вопроса (компетенция)
     * @type {string}
     * @memberof V1EntitiesDevelopmentTasksQuestionsQuestion
     */
    name: string;
}
/**
 * V1::Entities::DevelopmentTasks::Show model
 * @export
 * @interface V1EntitiesDevelopmentTasksShow
 */
export interface V1EntitiesDevelopmentTasksShow {
    /**
     * ID задания
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    id: number;
    /**
     * Текст задания
     * @type {string}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    name: string;
    /**
     * Вес задания
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    weight: number;
    /**
     * Прогресс выполнения
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    progress: number;
    /**
     * Статус задания, варианты: init, in_progress, finished
     * @type {string}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    status: string;
    /**
     * ИД вопроса (компетенция)
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    questionId?: number;
    /**
     * Текст вопроса (компетенция)
     * @type {string}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    questionName?: string;
    /**
     * Планируемая дата завершения (ISO8601)
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    scheduledAt?: number;
    /**
     * Фактическая дата завершения (ISO8601)
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksShow
     */
    finishedAt?: number;
}
/**
 * V1::Entities::DevelopmentTasks::Weights model
 * @export
 * @interface V1EntitiesDevelopmentTasksWeights
 */
export interface V1EntitiesDevelopmentTasksWeights {
    /**
     * Список задач с весами
     * @type {Array<V1EntitiesDevelopmentTasksWeightsWeight>}
     * @memberof V1EntitiesDevelopmentTasksWeights
     */
    weights: Array<V1EntitiesDevelopmentTasksWeightsWeight>;
}
/**
 * 
 * @export
 * @interface V1EntitiesDevelopmentTasksWeightsWeight
 */
export interface V1EntitiesDevelopmentTasksWeightsWeight {
    /**
     * ID вопроса
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksWeightsWeight
     */
    id: number;
    /**
     * Вес вопроса
     * @type {number}
     * @memberof V1EntitiesDevelopmentTasksWeightsWeight
     */
    weight: number;
}
/**
 * V1::Entities::ExpertLists::Public::AnalyticsIndex model
 * @export
 * @interface V1EntitiesExpertListsPublicAnalyticsIndex
 */
export interface V1EntitiesExpertListsPublicAnalyticsIndex {
    /**
     * Список групп экспертов
     * @type {Array<V1EntitiesExpertListsPublicAnalyticsIndexExpertList>}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndex
     */
    expertsLists: Array<V1EntitiesExpertListsPublicAnalyticsIndexExpertList>;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicAnalyticsIndexExpertList
 */
export interface V1EntitiesExpertListsPublicAnalyticsIndexExpertList {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    id: number;
    /**
     * Название группы экспертов (со стороны оцениваемого)
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    name: string;
    /**
     * Описание группы экспертов
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    description: string;
    /**
     * Добавление опрашиваемых разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    canAddSurveyee: boolean;
    /**
     * Можно удалять пары?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    canDeleteCouples: boolean;
    /**
     * Можно перемещать пары в группу?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    canReceiveCouplesOnMove: boolean;
    /**
     * Добавление внешнего эксперта разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    canAddExternalExperts: boolean;
    /**
     * Самооценка?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicAnalyticsIndexExpertList
     */
    selfAssessment: boolean;
}
/**
 * V1::Entities::ExpertLists::Public::Index model
 * @export
 * @interface V1EntitiesExpertListsPublicIndex
 */
export interface V1EntitiesExpertListsPublicIndex {
    /**
     * Список групп экспертов
     * @type {Array<V1EntitiesExpertListsPublicIndexExpertList>}
     * @memberof V1EntitiesExpertListsPublicIndex
     */
    expertsLists: Array<V1EntitiesExpertListsPublicIndexExpertList>;
    /**
     * Список пар, где текущий пользователь опрашиваемый
     * @type {Array<V1EntitiesExpertListsPublicIndexCouple>}
     * @memberof V1EntitiesExpertListsPublicIndex
     */
    couples: Array<V1EntitiesExpertListsPublicIndexCouple>;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicIndexCouple
 */
export interface V1EntitiesExpertListsPublicIndexCouple {
    /**
     * ID пары
     * @type {number}
     * @memberof V1EntitiesExpertListsPublicIndexCouple
     */
    id: number;
    /**
     * Статус
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCouple
     */
    status: string;
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesExpertListsPublicIndexCouple
     */
    groupId: number;
    /**
     * Возможность отказа от опроса
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexCouple
     */
    canReject: boolean;
    /**
     * 
     * @type {V1EntitiesExpertListsPublicIndexCoupleAudience}
     * @memberof V1EntitiesExpertListsPublicIndexCouple
     */
    expert: V1EntitiesExpertListsPublicIndexCoupleAudience;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicIndexCoupleAudience
 */
export interface V1EntitiesExpertListsPublicIndexCoupleAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexCoupleAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicIndexExpertList
 */
export interface V1EntitiesExpertListsPublicIndexExpertList {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    id: number;
    /**
     * Название группы экспертов (со стороны оцениваемого)
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    name: string;
    /**
     * Описание группы экспертов
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    description: string;
    /**
     * Добавление опрашиваемых разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    canAddSurveyee: boolean;
    /**
     * Можно удалять пары?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    canDeleteCouples: boolean;
    /**
     * Можно перемещать пары в группу?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    canReceiveCouplesOnMove: boolean;
    /**
     * Добавление внешнего эксперта разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    canAddExternalExperts: boolean;
    /**
     * Самооценка?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicIndexExpertList
     */
    selfAssessment: boolean;
}
/**
 * V1::Entities::ExpertLists::Public::RequestedExperts model
 * @export
 * @interface V1EntitiesExpertListsPublicRequestedExperts
 */
export interface V1EntitiesExpertListsPublicRequestedExperts {
    /**
     * Список групп экспертов
     * @type {Array<V1EntitiesExpertListsPublicRequestedExpertsExpertList>}
     * @memberof V1EntitiesExpertListsPublicRequestedExperts
     */
    expertsLists: Array<V1EntitiesExpertListsPublicRequestedExpertsExpertList>;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicRequestedExpertsAudience
 */
export interface V1EntitiesExpertListsPublicRequestedExpertsAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesExpertListsPublicRequestedExpertsExpertList
 */
export interface V1EntitiesExpertListsPublicRequestedExpertsExpertList {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    id: number;
    /**
     * Название группы экспертов
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    name: string;
    /**
     * Описание группы экспертов
     * @type {string}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    description: string;
    /**
     * Добавление опрашиваемых разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    canAddSurveyee: boolean;
    /**
     * Можно удалять пары?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    canDeleteCouples: boolean;
    /**
     * Можно перемещать пары в группу?
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    canReceiveCouplesOnMove: boolean;
    /**
     * Добавление внешнего эксперта разрешено
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    canAddExternalExperts: boolean;
    /**
     * Флаг самооценки
     * @type {boolean}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    selfAssessment: boolean;
    /**
     * Список экспертов
     * @type {Array<V1EntitiesExpertListsPublicRequestedExpertsAudience>}
     * @memberof V1EntitiesExpertListsPublicRequestedExpertsExpertList
     */
    experts: Array<V1EntitiesExpertListsPublicRequestedExpertsAudience>;
}
/**
 * V1::Entities::Invitations::Jwt model
 * @export
 * @interface V1EntitiesInvitationsJwt
 */
export interface V1EntitiesInvitationsJwt {
    /**
     * 
     * @type {string}
     * @memberof V1EntitiesInvitationsJwt
     */
    jwt: string;
}
/**
 * V1::Entities::Invitees::Public::Letter model
 * @export
 * @interface V1EntitiesInviteesPublicLetter
 */
export interface V1EntitiesInviteesPublicLetter {
    /**
     * 
     * @type {Array<V1EntitiesInviteesPublicLetterArg>}
     * @memberof V1EntitiesInviteesPublicLetter
     */
    args: Array<V1EntitiesInviteesPublicLetterArg>;
}
/**
 * 
 * @export
 * @interface V1EntitiesInviteesPublicLetterArg
 */
export interface V1EntitiesInviteesPublicLetterArg {
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesInviteesPublicLetterArg
     */
    name: string;
    /**
     * Тип
     * @type {string}
     * @memberof V1EntitiesInviteesPublicLetterArg
     */
    type: string;
    /**
     * Ключ
     * @type {string}
     * @memberof V1EntitiesInviteesPublicLetterArg
     */
    key: string;
    /**
     * Значание
     * @type {string}
     * @memberof V1EntitiesInviteesPublicLetterArg
     */
    value: string;
}
/**
 * V1::Entities::Invitees::Public::Search model
 * @export
 * @interface V1EntitiesInviteesPublicSearch
 */
export interface V1EntitiesInviteesPublicSearch {
    /**
     * 
     * @type {Array<V1EntitiesInviteesPublicSearchInvitee>}
     * @memberof V1EntitiesInviteesPublicSearch
     */
    invitees: Array<V1EntitiesInviteesPublicSearchInvitee>;
}
/**
 * 
 * @export
 * @interface V1EntitiesInviteesPublicSearchInvitee
 */
export interface V1EntitiesInviteesPublicSearchInvitee {
    /**
     * UUID внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    userId: string;
    /**
     * Почта внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    email: string;
    /**
     * Имя внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    firstName: string;
    /**
     * Фамилия внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    lastName: string;
    /**
     * Отчество внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    middleName: string;
    /**
     * Пол внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    sex: string;
    /**
     * Основная штатная должность внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    staffPositionMain: string;
    /**
     * Команды внешнего эксперта
     * @type {string}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    teamsWithDelimiter: string;
    /**
     * Выбран как эксперт для текущего пользователя?
     * @type {boolean}
     * @memberof V1EntitiesInviteesPublicSearchInvitee
     */
    selected: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesPaginateOutput
 */
export interface V1EntitiesPaginateOutput {
    /**
     * Выбранная страница
     * @type {number}
     * @memberof V1EntitiesPaginateOutput
     */
    page: number;
    /**
     * Количество элементов на страницу
     * @type {number}
     * @memberof V1EntitiesPaginateOutput
     */
    limit: number;
    /**
     * Всего элементов в базе
     * @type {number}
     * @memberof V1EntitiesPaginateOutput
     */
    total: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesReviewsAudience
 */
export interface V1EntitiesReviewsAudience {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesReviewsAudience
     */
    teamsWithDelimiter: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesReviewsCouple
 */
export interface V1EntitiesReviewsCouple {
    /**
     * ID пары
     * @type {number}
     * @memberof V1EntitiesReviewsCouple
     */
    id: number;
    /**
     * Статус пары
     * @type {string}
     * @memberof V1EntitiesReviewsCouple
     */
    status: string;
    /**
     * ID группы
     * @type {number}
     * @memberof V1EntitiesReviewsCouple
     */
    groupId: number;
    /**
     * 
     * @type {V1EntitiesReviewsAudience}
     * @memberof V1EntitiesReviewsCouple
     */
    expert: V1EntitiesReviewsAudience;
}
/**
 * V1::Entities::Reviews::Create model
 * @export
 * @interface V1EntitiesReviewsCreate
 */
export interface V1EntitiesReviewsCreate {
    /**
     * ID Ревью
     * @type {number}
     * @memberof V1EntitiesReviewsCreate
     */
    id: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesReviewsExpertList
 */
export interface V1EntitiesReviewsExpertList {
    /**
     * ID группы экспертов
     * @type {number}
     * @memberof V1EntitiesReviewsExpertList
     */
    id: number;
    /**
     * Название группы экспертов
     * @type {string}
     * @memberof V1EntitiesReviewsExpertList
     */
    name: string;
    /**
     * Описание группы экспертов
     * @type {string}
     * @memberof V1EntitiesReviewsExpertList
     */
    description: string;
    /**
     * Добавление внешнего эксперта разрешено
     * @type {boolean}
     * @memberof V1EntitiesReviewsExpertList
     */
    canAddExternalExperts: boolean;
}
/**
 * V1::Entities::Reviews::Index model
 * @export
 * @interface V1EntitiesReviewsIndex
 */
export interface V1EntitiesReviewsIndex {
    /**
     * Список согласований
     * @type {Array<V1EntitiesReviewsIndexReview>}
     * @memberof V1EntitiesReviewsIndex
     */
    reviews: Array<V1EntitiesReviewsIndexReview>;
}
/**
 * 
 * @export
 * @interface V1EntitiesReviewsIndexReview
 */
export interface V1EntitiesReviewsIndexReview {
    /**
     * Статус согласования
     * @type {string}
     * @memberof V1EntitiesReviewsIndexReview
     */
    status: string;
    /**
     * 
     * @type {V1EntitiesReviewsAudience}
     * @memberof V1EntitiesReviewsIndexReview
     */
    surveyee: V1EntitiesReviewsAudience;
}
/**
 * V1::Entities::Reviews::Show model
 * @export
 * @interface V1EntitiesReviewsShow
 */
export interface V1EntitiesReviewsShow {
    /**
     * 
     * @type {V1EntitiesReviewsShowReview}
     * @memberof V1EntitiesReviewsShow
     */
    review: V1EntitiesReviewsShowReview;
    /**
     * Список групп экспертов
     * @type {Array<V1EntitiesReviewsExpertList>}
     * @memberof V1EntitiesReviewsShow
     */
    expertLists: Array<V1EntitiesReviewsExpertList>;
    /**
     * Список пар
     * @type {Array<V1EntitiesReviewsCouple>}
     * @memberof V1EntitiesReviewsShow
     */
    couples: Array<V1EntitiesReviewsCouple>;
}
/**
 * 
 * @export
 * @interface V1EntitiesReviewsShowReview
 */
export interface V1EntitiesReviewsShowReview {
    /**
     * Статус согласования
     * @type {string}
     * @memberof V1EntitiesReviewsShowReview
     */
    status: string;
    /**
     * 
     * @type {V1EntitiesReviewsAudience}
     * @memberof V1EntitiesReviewsShowReview
     */
    surveyee: V1EntitiesReviewsAudience;
}
/**
 * V1::Entities::Surveys::Kanban model
 * @export
 * @interface V1EntitiesSurveysKanban
 */
export interface V1EntitiesSurveysKanban {
    /**
     * Список черновых опросов
     * @type {Array<V1EntitiesSurveysKanbanDraftSurvey>}
     * @memberof V1EntitiesSurveysKanban
     */
    drafts: Array<V1EntitiesSurveysKanbanDraftSurvey>;
    /**
     * Список опубликованных опросов
     * @type {Array<V1EntitiesSurveysKanbanPublicSurvey>}
     * @memberof V1EntitiesSurveysKanban
     */
    published: Array<V1EntitiesSurveysKanbanPublicSurvey>;
    /**
     * Список активных опросов
     * @type {Array<V1EntitiesSurveysKanbanActiveSurvey>}
     * @memberof V1EntitiesSurveysKanban
     */
    active: Array<V1EntitiesSurveysKanbanActiveSurvey>;
    /**
     * Список завершенных опросов
     * @type {Array<V1EntitiesSurveysKanbanFinishSurvey>}
     * @memberof V1EntitiesSurveysKanban
     */
    finished: Array<V1EntitiesSurveysKanbanFinishSurvey>;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysKanbanActiveSurvey
 */
export interface V1EntitiesSurveysKanbanActiveSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    name: string;
    /**
     * ID опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    slug: string;
    /**
     * Статус опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    status: string;
    /**
     * Тип аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    analyticsKind: string;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    viewingRights: string;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    userSurveySlugSettings: string;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    authorId: string;
    /**
     * Тип текущего этапа
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    currentStageKind: string;
    /**
     * Тэги опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    tags: string;
    /**
     * Дата запуска опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    startAt: string;
    /**
     * Дата завершения опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    finishAt: string;
    /**
     * Дней до окончания опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanActiveSurvey
     */
    daysBeforeFinish: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysKanbanDraftSurvey
 */
export interface V1EntitiesSurveysKanbanDraftSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    name: string;
    /**
     * ID опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    slug: string;
    /**
     * Статус опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    status: string;
    /**
     * Тип аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    analyticsKind: string;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    viewingRights: string;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    userSurveySlugSettings: string;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    authorId: string;
    /**
     * Тип текущего этапа
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    currentStageKind: string;
    /**
     * Тэги опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    tags: string;
    /**
     * Дата и время обновления опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanDraftSurvey
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysKanbanFinishSurvey
 */
export interface V1EntitiesSurveysKanbanFinishSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    name: string;
    /**
     * ID опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    slug: string;
    /**
     * Статус опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    status: string;
    /**
     * Тип аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    analyticsKind: string;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    viewingRights: string;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    userSurveySlugSettings: string;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    authorId: string;
    /**
     * Тип текущего этапа
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    currentStageKind: string;
    /**
     * Тэги опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    tags: string;
    /**
     * Дата запуска опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    startAt: string;
    /**
     * Дата завершения опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    finishAt: string;
    /**
     * Дней после окончания опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanFinishSurvey
     */
    daysAfterFinish: number;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysKanbanPublicSurvey
 */
export interface V1EntitiesSurveysKanbanPublicSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    name: string;
    /**
     * ID опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    slug: string;
    /**
     * Статус опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    status: string;
    /**
     * Тип аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    analyticsKind: string;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    viewingRights: string;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    userSurveySlugSettings: string;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    authorId: string;
    /**
     * Тип текущего этапа
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    currentStageKind: string;
    /**
     * Тэги опроса
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    tags: string;
    /**
     * Дата запуска опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    startAt: string;
    /**
     * Дата завершения опроса (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    finishAt: string;
    /**
     * Дней до начала опроса
     * @type {number}
     * @memberof V1EntitiesSurveysKanbanPublicSurvey
     */
    daysBeforeStart: number;
}
/**
 * V1::Entities::Surveys::Public::AnalyticsIndex model
 * @export
 * @interface V1EntitiesSurveysPublicAnalyticsIndex
 */
export interface V1EntitiesSurveysPublicAnalyticsIndex {
    /**
     * Public список опросов
     * @type {Array<V1EntitiesSurveysPublicAnalyticsIndexSurvey>}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndex
     */
    surveys: Array<V1EntitiesSurveysPublicAnalyticsIndexSurvey>;
    /**
     * Список исторических опросов
     * @type {Array<V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey>}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndex
     */
    historicalSurveys: Array<V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey>;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey
 */
export interface V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey
     */
    name: string;
    /**
     * Опрос NPS?
     * @type {boolean}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey
     */
    withNpsScale: boolean;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexHistoricalSurvey
     */
    dateEnd: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicAnalyticsIndexSurvey
 */
export interface V1EntitiesSurveysPublicAnalyticsIndexSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    name: string;
    /**
     * UUID картинки
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    image: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    description: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    dateEnd: string;
    /**
     * Опрос NPS?
     * @type {boolean}
     * @memberof V1EntitiesSurveysPublicAnalyticsIndexSurvey
     */
    withNpsScale: boolean;
}
/**
 * V1::Entities::Surveys::Public::Index model
 * @export
 * @interface V1EntitiesSurveysPublicIndex
 */
export interface V1EntitiesSurveysPublicIndex {
    /**
     * Public список опросов
     * @type {Array<V1EntitiesSurveysPublicIndexSurvey>}
     * @memberof V1EntitiesSurveysPublicIndex
     */
    surveys: Array<V1EntitiesSurveysPublicIndexSurvey>;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicIndexSurvey
 */
export interface V1EntitiesSurveysPublicIndexSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    name: string;
    /**
     * UUID картинки
     * @type {string}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    image: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    description: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicIndexSurvey
     */
    dateEnd: string;
}
/**
 * V1::Entities::Surveys::Public::SearchByExperts model
 * @export
 * @interface V1EntitiesSurveysPublicSearchByExperts
 */
export interface V1EntitiesSurveysPublicSearchByExperts {
    /**
     * Список экспертов
     * @type {Array<V1EntitiesSurveysPublicSearchByExpertsExpert>}
     * @memberof V1EntitiesSurveysPublicSearchByExperts
     */
    experts: Array<V1EntitiesSurveysPublicSearchByExpertsExpert>;
    /**
     * 
     * @type {V1EntitiesPaginateOutput}
     * @memberof V1EntitiesSurveysPublicSearchByExperts
     */
    pagination: V1EntitiesPaginateOutput;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicSearchByExpertsExpert
 */
export interface V1EntitiesSurveysPublicSearchByExpertsExpert {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    teamsWithDelimiter: string;
    /**
     * Выбран как эксперт для текущего пользователя?
     * @type {boolean}
     * @memberof V1EntitiesSurveysPublicSearchByExpertsExpert
     */
    selected?: boolean;
}
/**
 * V1::Entities::Surveys::Public::Show model
 * @export
 * @interface V1EntitiesSurveysPublicShow
 */
export interface V1EntitiesSurveysPublicShow {
    /**
     * ID опроса
     * @type {number}
     * @memberof V1EntitiesSurveysPublicShow
     */
    id: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    name: string;
    /**
     * UUID картинки
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    image: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    description: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    dateEnd: string;
    /**
     * Шаблон опроса
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    template: string;
    /**
     * Статус
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShow
     */
    status: string;
    /**
     * Настроки опроса
     * @type {Array<V1EntitiesSurveysPublicShowSetting>}
     * @memberof V1EntitiesSurveysPublicShow
     */
    settings: Array<V1EntitiesSurveysPublicShowSetting>;
    /**
     * Этапы
     * @type {Array<V1EntitiesSurveysPublicShowStage>}
     * @memberof V1EntitiesSurveysPublicShow
     */
    stages: Array<V1EntitiesSurveysPublicShowStage>;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicShowSetting
 */
export interface V1EntitiesSurveysPublicShowSetting {
    /**
     * Название параметра
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowSetting
     */
    name: string;
    /**
     * Ключ параметра
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowSetting
     */
    key: string;
    /**
     * Значение параметра
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowSetting
     */
    value: string;
    /**
     * Тип параметра
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowSetting
     */
    type: string;
}
/**
 * 
 * @export
 * @interface V1EntitiesSurveysPublicShowStage
 */
export interface V1EntitiesSurveysPublicShowStage {
    /**
     * Тип
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowStage
     */
    kind: string;
    /**
     * Очередность
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowStage
     */
    type: string;
    /**
     * Дата начала (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowStage
     */
    dateStart: string;
    /**
     * Дата окончания (ISO8601)
     * @type {string}
     * @memberof V1EntitiesSurveysPublicShowStage
     */
    dateEnd: string;
}
/**
 * V1::Entities::Users::Public::Me model
 * @export
 * @interface V1EntitiesUsersPublicMe
 */
export interface V1EntitiesUsersPublicMe {
    /**
     * 
     * @type {V1EntitiesUsersPublicMeUser}
     * @memberof V1EntitiesUsersPublicMe
     */
    user: V1EntitiesUsersPublicMeUser;
    /**
     * Возможность
     * @type {Array<string>}
     * @memberof V1EntitiesUsersPublicMe
     */
    abilities: Array<string>;
}
/**
 * 
 * @export
 * @interface V1EntitiesUsersPublicMeUser
 */
export interface V1EntitiesUsersPublicMeUser {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesUsersPublicMeUser
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::Users::Public::Show model
 * @export
 * @interface V1EntitiesUsersPublicShow
 */
export interface V1EntitiesUsersPublicShow {
    /**
     * 
     * @type {V1EntitiesUsersPublicShowUser}
     * @memberof V1EntitiesUsersPublicShow
     */
    user: V1EntitiesUsersPublicShowUser;
}
/**
 * 
 * @export
 * @interface V1EntitiesUsersPublicShowUser
 */
export interface V1EntitiesUsersPublicShowUser {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    userId: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    firstName: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    lastName: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    middleName: string;
    /**
     * Пол
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    sex: string;
    /**
     * Основная штатная должность
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    staffPositionMain: string;
    /**
     * Команды
     * @type {string}
     * @memberof V1EntitiesUsersPublicShowUser
     */
    teamsWithDelimiter: string;
}
/**
 * V1::Entities::YandexGpt::Weakness model
 * @export
 * @interface V1EntitiesYandexGptWeakness
 */
export interface V1EntitiesYandexGptWeakness {
    /**
     * Статус отчета
     * @type {string}
     * @memberof V1EntitiesYandexGptWeakness
     */
    status?: string;
    /**
     * Результаты отчета
     * @type {Array<V1EntitiesYandexGptWeaknessResponse>}
     * @memberof V1EntitiesYandexGptWeakness
     */
    response?: Array<V1EntitiesYandexGptWeaknessResponse>;
    /**
     * Стоимость отчета
     * @type {number}
     * @memberof V1EntitiesYandexGptWeakness
     */
    cost?: number;
    /**
     * Баланс токенов компании
     * @type {number}
     * @memberof V1EntitiesYandexGptWeakness
     */
    companyBalance?: number;
    /**
     * Пользователь исчерпал свой лимит?
     * @type {boolean}
     * @memberof V1EntitiesYandexGptWeakness
     */
    userOverheated?: boolean;
}
/**
 * 
 * @export
 * @interface V1EntitiesYandexGptWeaknessResponse
 */
export interface V1EntitiesYandexGptWeaknessResponse {
    /**
     * Название
     * @type {string}
     * @memberof V1EntitiesYandexGptWeaknessResponse
     */
    name?: string;
    /**
     * Содержимое
     * @type {string}
     * @memberof V1EntitiesYandexGptWeaknessResponse
     */
    content?: string;
    /**
     * Ошибка
     * @type {string}
     * @memberof V1EntitiesYandexGptWeaknessResponse
     */
    error?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * DELETE /v1/analytics/feedback/development_tasks/:id
         * @summary Удаление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1AnalyticsFeedbackDevelopmentTasksId: async (id: number, surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1AnalyticsFeedbackDevelopmentTasksId', 'id', id)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('deleteV1AnalyticsFeedbackDevelopmentTasksId', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /v1/couples/:couple_id
         * @summary Удаление пары (выбор экспертов)
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CouplesCoupleId: async (coupleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('deleteV1CouplesCoupleId', 'coupleId', coupleId)
            const localVarPath = `/v1/couples/{couple_id}`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id
         * @summary Удаление эксперта у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} expertId ID эксперта
         * @param {number} [expertListId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ReviewsSurveyIdSurveyeeId: async (surveyId: number, surveyeeId: string, expertId: string, expertListId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeId', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeId', 'surveyeeId', surveyeeId)
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeId', 'expertId', expertId)
            const localVarPath = `/v1/reviews/{survey_id}/{surveyee_id}`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertId !== undefined) {
                localVarQueryParameter['expert_id'] = expertId;
            }

            if (expertListId !== undefined) {
                localVarQueryParameter['expert_list_id'] = expertListId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id/delete_reviewer
         * @summary Удалить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer: async (surveyId: number, surveyeeId: string, reviewerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer', 'surveyeeId', surveyeeId)
            // verify required parameter 'reviewerId' is not null or undefined
            assertParamExists('deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer', 'reviewerId', reviewerId)
            const localVarPath = `/v1/reviews/{survey_id}/{surveyee_id}/delete_reviewer`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reviewerId !== undefined) {
                localVarQueryParameter['reviewer_id'] = reviewerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/average_rate
         * @summary Средний бал
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {boolean} [withoutSelf] Без учета самооценки по всем группам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackAverageRate: async (surveyId: number, groupId?: number, userId?: string, withoutSelf?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackAverageRate', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/average_rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (withoutSelf !== undefined) {
                localVarQueryParameter['without_self'] = withoutSelf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/behavior/answers
         * @summary Поведение варианты ответов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackBehaviorAnswers: async (surveyId: number, userId?: string, groupId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackBehaviorAnswers', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/behavior/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/behavior/dynamics
         * @summary Динамика поведенческих вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackBehaviorDynamics: async (surveyId: number, userId?: string, groupId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackBehaviorDynamics', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/behavior/dynamics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/destructors/dynamics
         * @summary Динамика деструкторов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDestructorsDynamics: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDestructorsDynamics', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/destructors/dynamics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/destructors/survey
         * @summary Деструкторы в текущем опросе
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDestructorsSurvey: async (surveyId: number, groupId?: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDestructorsSurvey', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/destructors/survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/development_tasks
         * @summary Список задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasks: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasks', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/:id
         * @summary Просмотр задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksId: async (id: number, surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasksId', 'id', id)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasksId', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/progress
         * @summary Прогресс по ИПР для сотруднка
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksProgress: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasksProgress', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/questions
         * @summary Список вопросов для ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksQuestions: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasksQuestions', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/weights
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksWeights: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackDevelopmentTasksWeights', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/weights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/dynamics
         * @summary Динамика доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsDynamics: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackExtraQuestionsDynamics', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/extra_questions/dynamics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/scales
         * @summary Шкала распределения доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsScales: async (surveyId: number, groupId?: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackExtraQuestionsScales', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/extra_questions/scales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/temp
         * @summary Тепловая карта доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsTemp: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackExtraQuestionsTemp', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/extra_questions/temp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/general_questions/answers
         * @summary Комментарии к основным вопросам
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsAnswers: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackGeneralQuestionsAnswers', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/general_questions/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/general_questions/comments
         * @summary Комментарии из тепловая карты
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsComments: async (surveyId: number, questionId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackGeneralQuestionsComments', 'surveyId', surveyId)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackGeneralQuestionsComments', 'questionId', questionId)
            const localVarPath = `/v1/analytics/feedback/general_questions/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/general_questions/temp
         * @summary Тепловая карта компетенций
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsTemp: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackGeneralQuestionsTemp', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/general_questions/temp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/general_questions/top
         * @summary Топ результаты
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsTop: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackGeneralQuestionsTop', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/general_questions/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/johari
         * @summary Окно Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackJohari: async (surveyId: number, groupId?: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackJohari', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/johari`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/johari/questions
         * @summary Подсказка для Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackJohariQuestions: async (surveyId: number, groupId?: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackJohariQuestions', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/johari/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/comments
         * @summary Комментарии из тепловая карты для NPS
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackNpsQuestionsComments: async (surveyId: number, questionId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackNpsQuestionsComments', 'surveyId', surveyId)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackNpsQuestionsComments', 'questionId', questionId)
            const localVarPath = `/v1/analytics/feedback/nps_questions/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/temp
         * @summary Тепловая карта NPS
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackNpsQuestionsTemp: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackNpsQuestionsTemp', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/nps_questions/temp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/open_questions/answers
         * @summary Ответы на открытые вопросы
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsAnswers: async (surveyId: number, groupId?: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackOpenQuestionsAnswers', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/open_questions/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/open_questions/list_comments
         * @summary Список ответов по всем опросам и всем вопросам | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsListComments: async (surveyIds?: Array<number>, userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics/feedback/open_questions/list_comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyIds) {
                localVarQueryParameter['survey_ids[]'] = surveyIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/open_questions/top_comments
         * @summary Топ 5 комментов | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsTopComments: async (surveyIds?: Array<number>, userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics/feedback/open_questions/top_comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyIds) {
                localVarQueryParameter['survey_ids[]'] = surveyIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/feedback/xlsx_report
         * @summary Эксель выгрузка одиночного пользователя
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackXlsxReport: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsFeedbackXlsxReport', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/xlsx_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/filters
         * @summary Получить данные для фильтров
         * @param {boolean} [reload] сбросить кэш?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFilters: async (reload?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reload !== undefined) {
                localVarQueryParameter['reload'] = reload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/management/surveys
         * @summary Список доступных опросов
         * @param {string} [userId] ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsManagementSurveys: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics/management/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsManagementXlsxReport: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsManagementXlsxReport', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/management/xlsx_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/surveys
         * @summary Получение списка опросов для аналитики
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveys: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов для аналитики
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdExpertsLists: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsSurveysSurveyIdExpertsLists', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/surveys/{survey_id}/experts_lists`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/pages
         * @summary Список страниц для PDF
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdPages: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsSurveysSurveyIdPages', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/surveys/{survey_id}/pages`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/statistics/passing/export/xlsx
         * @summary Статистика прохождения выгрузка в xlsx формате
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/surveys/{survey_id}/statistics/passing/export/xlsx`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/tabs
         * @summary Активные табы
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdTabs: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1AnalyticsSurveysSurveyIdTabs', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/surveys/{survey_id}/tabs`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/couples/:couple_id/assessment
         * @summary Оценка
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CouplesCoupleIdAssessment: async (coupleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('getV1CouplesCoupleIdAssessment', 'coupleId', coupleId)
            const localVarPath = `/v1/couples/{couple_id}/assessment`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/couples/iam_expert
         * @summary Пары по опросу для эксперта
         * @param {number} surveyId ID опроса
         * @param {Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>} [status] Массив статусов
         * @param {Array<'favorite'>} [without] Исключает пары: favorite - исключить сотрудников с favorite&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CouplesIamExpert: async (surveyId: number, status?: Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>, without?: Array<'favorite'>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1CouplesIamExpert', 'surveyId', surveyId)
            const localVarPath = `/v1/couples/iam_expert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (status) {
                localVarQueryParameter['status[]'] = status;
            }

            if (without) {
                localVarQueryParameter['without[]'] = without;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/demo/assessment
         * @summary Демо Оценки
         * @param {number} surveyId ID Опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1DemoAssessment: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1DemoAssessment', 'surveyId', surveyId)
            const localVarPath = `/v1/demo/assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/invitees/letter
         * @summary Переменные для шаблона письма внешнему эксперту
         * @param {number} expertListId ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1InviteesLetter: async (expertListId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertListId' is not null or undefined
            assertParamExists('getV1InviteesLetter', 'expertListId', expertListId)
            const localVarPath = `/v1/invitees/letter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expertListId !== undefined) {
                localVarQueryParameter['expert_list_id'] = expertListId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/invitees/search
         * @summary Поиск внешнего эксперта
         * @param {string} email Почта внешнего эксперта, поиск по полному вхождению, регистронезависимый
         * @param {number} expertListId ID группы экспертов
         * @param {string} [userId] ID оцениваемого (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1InviteesSearch: async (email: string, expertListId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getV1InviteesSearch', 'email', email)
            // verify required parameter 'expertListId' is not null or undefined
            assertParamExists('getV1InviteesSearch', 'expertListId', expertListId)
            const localVarPath = `/v1/invitees/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (expertListId !== undefined) {
                localVarQueryParameter['expert_list_id'] = expertListId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Me: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/reviews/:survey_id
         * @summary Получение списка оцениваемых и статус их ревью
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ReviewsSurveyId: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1ReviewsSurveyId', 'surveyId', surveyId)
            const localVarPath = `/v1/reviews/{survey_id}`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/reviews/:survey_id/:surveyee_id
         * @summary Получение списка экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ReviewsSurveyIdSurveyeeId: async (surveyId: number, surveyeeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1ReviewsSurveyIdSurveyeeId', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('getV1ReviewsSurveyIdSurveyeeId', 'surveyeeId', surveyeeId)
            const localVarPath = `/v1/reviews/{survey_id}/{surveyee_id}`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys
         * @summary Получение списка опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Surveys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/kanban
         * @summary Получение канбана опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysKanban: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/surveys/kanban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/:survey_id
         * @summary Получения данных об опросе по id
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyId: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1SurveysSurveyId', 'surveyId', surveyId)
            const localVarPath = `/v1/surveys/{survey_id}`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/:survey_id/audiences
         * @summary Поиск аудитории
         * @param {number} surveyId ID Опроса
         * @param {'expert' | 'surveyee'} withoutIam Исключает сотрудников имеющие пары с current_user: expert - исключить сотрудников с кем current_user имеет пары в роли эксперта, surveyee - исключить сотрудников с кем current_user имеет пары в роли оцениваемого
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdAudiences: async (surveyId: number, withoutIam: 'expert' | 'surveyee', query?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1SurveysSurveyIdAudiences', 'surveyId', surveyId)
            // verify required parameter 'withoutIam' is not null or undefined
            assertParamExists('getV1SurveysSurveyIdAudiences', 'withoutIam', withoutIam)
            const localVarPath = `/v1/surveys/{survey_id}/audiences`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (withoutIam !== undefined) {
                localVarQueryParameter['without_iam'] = withoutIam;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов в опросе
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdExpertsLists: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1SurveysSurveyIdExpertsLists', 'surveyId', surveyId)
            const localVarPath = `/v1/surveys/{survey_id}/experts_lists`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/:survey_id/requested_experts
         * @summary Получение списка экспертов, кому отправлен запрос на получение обратной связи
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdRequestedExperts: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1SurveysSurveyIdRequestedExperts', 'surveyId', surveyId)
            const localVarPath = `/v1/surveys/{survey_id}/requested_experts`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/surveys/:survey_id/search-by-experts
         * @summary Поиск экспертов по всей компании
         * @param {number} surveyId ID опроса
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {string} [userId] Текущий пользователь
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdSearchByExperts: async (surveyId: number, query?: string, userId?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getV1SurveysSurveyIdSearchByExperts', 'surveyId', surveyId)
            const localVarPath = `/v1/surveys/{survey_id}/search-by-experts`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v1/users/:id
         * @summary Информация по сотруднику
         * @param {string} id ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getV1UsersId', 'id', id)
            const localVarPath = `/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/development_tasks
         * @summary Создание задач ИПР
         * @param {PostV1AnalyticsFeedbackDevelopmentTasks} postV1AnalyticsFeedbackDevelopmentTasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasks: async (postV1AnalyticsFeedbackDevelopmentTasks: PostV1AnalyticsFeedbackDevelopmentTasks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsFeedbackDevelopmentTasks' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasks', 'postV1AnalyticsFeedbackDevelopmentTasks', postV1AnalyticsFeedbackDevelopmentTasks)
            const localVarPath = `/v1/analytics/feedback/development_tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackDevelopmentTasks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/auto_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute: async (surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/auto_distribute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/:id/finish
         * @summary Завершение задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksIdFinish: async (id: number, surveyId: number, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasksIdFinish', 'id', id)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasksIdFinish', 'surveyId', surveyId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/manual_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute} postV1AnalyticsFeedbackDevelopmentTasksManualDistribute 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksManualDistribute: async (surveyId: number, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute: PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasksManualDistribute', 'surveyId', surveyId)
            // verify required parameter 'postV1AnalyticsFeedbackDevelopmentTasksManualDistribute' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackDevelopmentTasksManualDistribute', 'postV1AnalyticsFeedbackDevelopmentTasksManualDistribute', postV1AnalyticsFeedbackDevelopmentTasksManualDistribute)
            const localVarPath = `/v1/analytics/feedback/development_tasks/manual_distribute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackDevelopmentTasksManualDistribute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_competentions
         * @summary Динамика основных вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions: async (postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions', 'postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions', postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions)
            const localVarPath = `/v1/analytics/feedback/general_questions/dynamics_by_competentions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_indicators
         * @summary Динамика основных вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators: async (postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators', 'postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators', postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators)
            const localVarPath = `/v1/analytics/feedback/general_questions/dynamics_by_indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_competentions
         * @summary Динамика NPS вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions: async (postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions', 'postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions', postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions)
            const localVarPath = `/v1/analytics/feedback/nps_questions/dynamics_by_competentions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_indicators
         * @summary Динамика NPS вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators: async (postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators' is not null or undefined
            assertParamExists('postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators', 'postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators', postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators)
            const localVarPath = `/v1/analytics/feedback/nps_questions/dynamics_by_indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/audiences
         * @summary Поиск сотрудника в аудитории опросов
         * @param {PostV1AnalyticsManagementAudiences} postV1AnalyticsManagementAudiences 
         * @param {number} [surveyId] ID опроса
         * @param {string} [query] Критерий поиска (минимум 1 символ, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementAudiences: async (postV1AnalyticsManagementAudiences: PostV1AnalyticsManagementAudiences, surveyId?: number, query?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementAudiences' is not null or undefined
            assertParamExists('postV1AnalyticsManagementAudiences', 'postV1AnalyticsManagementAudiences', postV1AnalyticsManagementAudiences)
            const localVarPath = `/v1/analytics/management/audiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementAudiences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/dashboards
         * @summary Доски сотрудников для руководителя
         * @param {PostV1AnalyticsManagementDashboards} postV1AnalyticsManagementDashboards 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDashboards: async (postV1AnalyticsManagementDashboards: PostV1AnalyticsManagementDashboards, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementDashboards' is not null or undefined
            assertParamExists('postV1AnalyticsManagementDashboards', 'postV1AnalyticsManagementDashboards', postV1AnalyticsManagementDashboards)
            const localVarPath = `/v1/analytics/management/dashboards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementDashboards, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/difference/heatmap
         * @summary Тепловая карта сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceHeatmap} postV1AnalyticsManagementDifferenceHeatmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDifferenceHeatmap: async (postV1AnalyticsManagementDifferenceHeatmap: PostV1AnalyticsManagementDifferenceHeatmap, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementDifferenceHeatmap' is not null or undefined
            assertParamExists('postV1AnalyticsManagementDifferenceHeatmap', 'postV1AnalyticsManagementDifferenceHeatmap', postV1AnalyticsManagementDifferenceHeatmap)
            const localVarPath = `/v1/analytics/management/difference/heatmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementDifferenceHeatmap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/difference/xlsx_report
         * @summary Xlsx-отчет сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceXlsxReport} postV1AnalyticsManagementDifferenceXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDifferenceXlsxReport: async (postV1AnalyticsManagementDifferenceXlsxReport: PostV1AnalyticsManagementDifferenceXlsxReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementDifferenceXlsxReport' is not null or undefined
            assertParamExists('postV1AnalyticsManagementDifferenceXlsxReport', 'postV1AnalyticsManagementDifferenceXlsxReport', postV1AnalyticsManagementDifferenceXlsxReport)
            const localVarPath = `/v1/analytics/management/difference/xlsx_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementDifferenceXlsxReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/expert_lists
         * @summary Список групп экпертов
         * @param {PostV1AnalyticsManagementExpertLists} postV1AnalyticsManagementExpertLists 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementExpertLists: async (postV1AnalyticsManagementExpertLists: PostV1AnalyticsManagementExpertLists, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementExpertLists' is not null or undefined
            assertParamExists('postV1AnalyticsManagementExpertLists', 'postV1AnalyticsManagementExpertLists', postV1AnalyticsManagementExpertLists)
            const localVarPath = `/v1/analytics/management/expert_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementExpertLists, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/result
         * @summary Список результатов опросов по сотрудникам (с user_ids)
         * @param {PostV1AnalyticsManagementResult} postV1AnalyticsManagementResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementResult: async (postV1AnalyticsManagementResult: PostV1AnalyticsManagementResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementResult' is not null or undefined
            assertParamExists('postV1AnalyticsManagementResult', 'postV1AnalyticsManagementResult', postV1AnalyticsManagementResult)
            const localVarPath = `/v1/analytics/management/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/subordinate_by_ids
         * @summary Сотрудники по id
         * @param {PostV1AnalyticsManagementSubordinateByIds} postV1AnalyticsManagementSubordinateByIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementSubordinateByIds: async (postV1AnalyticsManagementSubordinateByIds: PostV1AnalyticsManagementSubordinateByIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementSubordinateByIds' is not null or undefined
            assertParamExists('postV1AnalyticsManagementSubordinateByIds', 'postV1AnalyticsManagementSubordinateByIds', postV1AnalyticsManagementSubordinateByIds)
            const localVarPath = `/v1/analytics/management/subordinate_by_ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementSubordinateByIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/surveys
         * @summary Список доступных опросов (с user_ids)
         * @param {PostV1AnalyticsManagementSurveys} postV1AnalyticsManagementSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementSurveys: async (postV1AnalyticsManagementSurveys: PostV1AnalyticsManagementSurveys, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementSurveys' is not null or undefined
            assertParamExists('postV1AnalyticsManagementSurveys', 'postV1AnalyticsManagementSurveys', postV1AnalyticsManagementSurveys)
            const localVarPath = `/v1/analytics/management/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementSurveys, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {PostV1AnalyticsManagementXlsxReport} postV1AnalyticsManagementXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementXlsxReport: async (postV1AnalyticsManagementXlsxReport: PostV1AnalyticsManagementXlsxReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsManagementXlsxReport' is not null or undefined
            assertParamExists('postV1AnalyticsManagementXlsxReport', 'postV1AnalyticsManagementXlsxReport', postV1AnalyticsManagementXlsxReport)
            const localVarPath = `/v1/analytics/management/xlsx_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsManagementXlsxReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExperts: async (postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewChooseExperts' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewChooseExperts', 'postV1AnalyticsOverviewChooseExperts', postV1AnalyticsOverviewChooseExperts)
            const localVarPath = `/v1/analytics/overview/choose-experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewChooseExperts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExpertsList: async (postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewChooseExpertsList' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewChooseExpertsList', 'postV1AnalyticsOverviewChooseExpertsList', postV1AnalyticsOverviewChooseExpertsList)
            const localVarPath = `/v1/analytics/overview/choose-experts-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewChooseExpertsList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExpertsList_1: async (postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewChooseExpertsList' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewChooseExpertsList_1', 'postV1AnalyticsOverviewChooseExpertsList', postV1AnalyticsOverviewChooseExpertsList)
            const localVarPath = `/v1/analytics/overview/choose-experts-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewChooseExpertsList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExperts_2: async (postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewChooseExperts' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewChooseExperts_2', 'postV1AnalyticsOverviewChooseExperts', postV1AnalyticsOverviewChooseExperts)
            const localVarPath = `/v1/analytics/overview/choose-experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewChooseExperts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/send-notification/choose-experts
         * @summary Отправка письма сотрудникам (этап выбра экперта)
         * @param {PostV1AnalyticsOverviewSendNotificationChooseExperts} postV1AnalyticsOverviewSendNotificationChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationChooseExperts: async (postV1AnalyticsOverviewSendNotificationChooseExperts: PostV1AnalyticsOverviewSendNotificationChooseExperts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewSendNotificationChooseExperts' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewSendNotificationChooseExperts', 'postV1AnalyticsOverviewSendNotificationChooseExperts', postV1AnalyticsOverviewSendNotificationChooseExperts)
            const localVarPath = `/v1/analytics/overview/send-notification/choose-experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewSendNotificationChooseExperts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationStartSurvey: async (postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewSendNotificationStartSurvey' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewSendNotificationStartSurvey', 'postV1AnalyticsOverviewSendNotificationStartSurvey', postV1AnalyticsOverviewSendNotificationStartSurvey)
            const localVarPath = `/v1/analytics/overview/send-notification/start-survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewSendNotificationStartSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationStartSurvey_3: async (postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewSendNotificationStartSurvey' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewSendNotificationStartSurvey_3', 'postV1AnalyticsOverviewSendNotificationStartSurvey', postV1AnalyticsOverviewSendNotificationStartSurvey)
            const localVarPath = `/v1/analytics/overview/send-notification/start-survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewSendNotificationStartSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurvey: async (postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewStartSurvey' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewStartSurvey', 'postV1AnalyticsOverviewStartSurvey', postV1AnalyticsOverviewStartSurvey)
            const localVarPath = `/v1/analytics/overview/start-survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewStartSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurveyList: async (postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewStartSurveyList' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewStartSurveyList', 'postV1AnalyticsOverviewStartSurveyList', postV1AnalyticsOverviewStartSurveyList)
            const localVarPath = `/v1/analytics/overview/start-survey-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewStartSurveyList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurveyList_4: async (postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewStartSurveyList' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewStartSurveyList_4', 'postV1AnalyticsOverviewStartSurveyList', postV1AnalyticsOverviewStartSurveyList)
            const localVarPath = `/v1/analytics/overview/start-survey-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewStartSurveyList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurvey_5: async (postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1AnalyticsOverviewStartSurvey' is not null or undefined
            assertParamExists('postV1AnalyticsOverviewStartSurvey_5', 'postV1AnalyticsOverviewStartSurvey', postV1AnalyticsOverviewStartSurvey)
            const localVarPath = `/v1/analytics/overview/start-survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1AnalyticsOverviewStartSurvey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples
         * @summary Создание пары c current_user
         * @param {PostV1Couples} postV1Couples 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Couples: async (postV1Couples: PostV1Couples, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1Couples' is not null or undefined
            assertParamExists('postV1Couples', 'postV1Couples', postV1Couples)
            const localVarPath = `/v1/couples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1Couples, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/by_batch
         * @summary Массовое создание пар
         * @param {PostV1CouplesByBatch} postV1CouplesByBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesByBatch: async (postV1CouplesByBatch: PostV1CouplesByBatch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1CouplesByBatch' is not null or undefined
            assertParamExists('postV1CouplesByBatch', 'postV1CouplesByBatch', postV1CouplesByBatch)
            const localVarPath = `/v1/couples/by_batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1CouplesByBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/:couple_id/assessment/answer
         * @summary Сохранение оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdAssessmentAnswer} postV1CouplesCoupleIdAssessmentAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdAssessmentAnswer: async (coupleId: number, postV1CouplesCoupleIdAssessmentAnswer: PostV1CouplesCoupleIdAssessmentAnswer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdAssessmentAnswer', 'coupleId', coupleId)
            // verify required parameter 'postV1CouplesCoupleIdAssessmentAnswer' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdAssessmentAnswer', 'postV1CouplesCoupleIdAssessmentAnswer', postV1CouplesCoupleIdAssessmentAnswer)
            const localVarPath = `/v1/couples/{couple_id}/assessment/answer`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1CouplesCoupleIdAssessmentAnswer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/:couple_id/assessment/done
         * @summary Оценка завершена
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdAssessmentDone: async (coupleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdAssessmentDone', 'coupleId', coupleId)
            const localVarPath = `/v1/couples/{couple_id}/assessment/done`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/:couple_id/move-to-expert-list
         * @summary Перемещение пары для экперта на этапе прохождения
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdMoveToExpertList} postV1CouplesCoupleIdMoveToExpertList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdMoveToExpertList: async (coupleId: number, postV1CouplesCoupleIdMoveToExpertList: PostV1CouplesCoupleIdMoveToExpertList, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdMoveToExpertList', 'coupleId', coupleId)
            // verify required parameter 'postV1CouplesCoupleIdMoveToExpertList' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdMoveToExpertList', 'postV1CouplesCoupleIdMoveToExpertList', postV1CouplesCoupleIdMoveToExpertList)
            const localVarPath = `/v1/couples/{couple_id}/move-to-expert-list`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1CouplesCoupleIdMoveToExpertList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/:couple_id/regain
         * @summary Перенос из rejected в pending
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdRegain: async (coupleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdRegain', 'coupleId', coupleId)
            const localVarPath = `/v1/couples/{couple_id}/regain`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/:couple_id/rejected
         * @summary Отказ от оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdRejected} postV1CouplesCoupleIdRejected 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdRejected: async (coupleId: number, postV1CouplesCoupleIdRejected: PostV1CouplesCoupleIdRejected, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupleId' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdRejected', 'coupleId', coupleId)
            // verify required parameter 'postV1CouplesCoupleIdRejected' is not null or undefined
            assertParamExists('postV1CouplesCoupleIdRejected', 'postV1CouplesCoupleIdRejected', postV1CouplesCoupleIdRejected)
            const localVarPath = `/v1/couples/{couple_id}/rejected`
                .replace(`{${"couple_id"}}`, encodeURIComponent(String(coupleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1CouplesCoupleIdRejected, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/couples/favorite
         * @summary Добавить пары в избранное
         * @param {PostV1CouplesFavorite} postV1CouplesFavorite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesFavorite: async (postV1CouplesFavorite: PostV1CouplesFavorite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1CouplesFavorite' is not null or undefined
            assertParamExists('postV1CouplesFavorite', 'postV1CouplesFavorite', postV1CouplesFavorite)
            const localVarPath = `/v1/couples/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1CouplesFavorite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/invitations
         * @summary Получение JWT по токену приглашения
         * @param {PostV1Invitations} postV1Invitations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Invitations: async (postV1Invitations: PostV1Invitations, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1Invitations' is not null or undefined
            assertParamExists('postV1Invitations', 'postV1Invitations', postV1Invitations)
            const localVarPath = `/v1/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1Invitations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/invitees
         * @summary Добавление внешнего эксперта к оцениваемому (создание пары)
         * @param {PostV1Invitees} postV1Invitees 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Invitees: async (postV1Invitees: PostV1Invitees, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1Invitees' is not null or undefined
            assertParamExists('postV1Invitees', 'postV1Invitees', postV1Invitees)
            const localVarPath = `/v1/invitees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1Invitees, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/reviews/:survey_id/all
         * @summary Согласование всех экспертов у всех оцениваемых
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdAll: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdAll', 'surveyId', surveyId)
            const localVarPath = `/v1/reviews/{survey_id}/all`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/reviews/:survey_id/approve/:surveyee_id
         * @summary Согласование всех экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdApproveSurveyeeId: async (surveyId: number, surveyeeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdApproveSurveyeeId', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdApproveSurveyeeId', 'surveyeeId', surveyeeId)
            const localVarPath = `/v1/reviews/{survey_id}/approve/{surveyee_id}`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/reviews/:survey_id/:surveyee_id/create_reviewer
         * @summary Создать у оцениваемого ревью с ответственным
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdSurveyeeIdCreateReviewer: async (surveyId: number, surveyeeId: string, reviewerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdSurveyeeIdCreateReviewer', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdSurveyeeIdCreateReviewer', 'surveyeeId', surveyeeId)
            // verify required parameter 'reviewerId' is not null or undefined
            assertParamExists('postV1ReviewsSurveyIdSurveyeeIdCreateReviewer', 'reviewerId', reviewerId)
            const localVarPath = `/v1/reviews/{survey_id}/{surveyee_id}/create_reviewer`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reviewerId !== undefined) {
                localVarQueryParameter['reviewer_id'] = reviewerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v1/yandex_gpt/weakness
         * @summary Ручка для генерации и получения плана развития топ-3 проседающих компетенций ГПТ
         * @param {PostV1YandexGptWeakness} postV1YandexGptWeakness 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1YandexGptWeakness: async (postV1YandexGptWeakness: PostV1YandexGptWeakness, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postV1YandexGptWeakness' is not null or undefined
            assertParamExists('postV1YandexGptWeakness', 'postV1YandexGptWeakness', postV1YandexGptWeakness)
            const localVarPath = `/v1/yandex_gpt/weakness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postV1YandexGptWeakness, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /v1/analytics/feedback/development_tasks/:id
         * @summary Обновление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {PutV1AnalyticsFeedbackDevelopmentTasksId} putV1AnalyticsFeedbackDevelopmentTasksId 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1AnalyticsFeedbackDevelopmentTasksId: async (id: number, surveyId: number, putV1AnalyticsFeedbackDevelopmentTasksId: PutV1AnalyticsFeedbackDevelopmentTasksId, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putV1AnalyticsFeedbackDevelopmentTasksId', 'id', id)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('putV1AnalyticsFeedbackDevelopmentTasksId', 'surveyId', surveyId)
            // verify required parameter 'putV1AnalyticsFeedbackDevelopmentTasksId' is not null or undefined
            assertParamExists('putV1AnalyticsFeedbackDevelopmentTasksId', 'putV1AnalyticsFeedbackDevelopmentTasksId', putV1AnalyticsFeedbackDevelopmentTasksId)
            const localVarPath = `/v1/analytics/feedback/development_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['survey_id'] = surveyId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putV1AnalyticsFeedbackDevelopmentTasksId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /v1/reviews/:survey_id/:surveyee_id/update_reviewer
         * @summary Заменить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer: async (surveyId: number, surveyeeId: string, reviewerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer', 'surveyId', surveyId)
            // verify required parameter 'surveyeeId' is not null or undefined
            assertParamExists('putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer', 'surveyeeId', surveyeeId)
            // verify required parameter 'reviewerId' is not null or undefined
            assertParamExists('putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer', 'reviewerId', reviewerId)
            const localVarPath = `/v1/reviews/{survey_id}/{surveyee_id}/update_reviewer`
                .replace(`{${"survey_id"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"surveyee_id"}}`, encodeURIComponent(String(surveyeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reviewerId !== undefined) {
                localVarQueryParameter['reviewer_id'] = reviewerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * DELETE /v1/analytics/feedback/development_tasks/:id
         * @summary Удаление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /v1/couples/:couple_id
         * @summary Удаление пары (выбор экспертов)
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CouplesCoupleId(coupleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CouplesCoupleId(coupleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id
         * @summary Удаление эксперта у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} expertId ID эксперта
         * @param {number} [expertListId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, expertId: string, expertListId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, expertId, expertListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id/delete_reviewer
         * @summary Удалить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId, surveyeeId, reviewerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/average_rate
         * @summary Средний бал
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {boolean} [withoutSelf] Без учета самооценки по всем группам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackAverageRate(surveyId: number, groupId?: number, userId?: string, withoutSelf?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackAverageRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackAverageRate(surveyId, groupId, userId, withoutSelf, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/behavior/answers
         * @summary Поведение варианты ответов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackBehaviorAnswers(surveyId: number, userId?: string, groupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackBehaviorAnswers(surveyId, userId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/behavior/dynamics
         * @summary Динамика поведенческих вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackBehaviorDynamics(surveyId: number, userId?: string, groupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackBehaviorDynamics(surveyId, userId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/destructors/dynamics
         * @summary Динамика деструкторов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDestructorsDynamics(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackDestructorsDynamics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDestructorsDynamics(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/destructors/survey
         * @summary Деструкторы в текущем опросе
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDestructorsSurvey(surveyId: number, groupId?: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackDestructorsSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDestructorsSurvey(surveyId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/development_tasks
         * @summary Список задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDevelopmentTasks(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackDevelopmentTasksIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDevelopmentTasks(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/:id
         * @summary Просмотр задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesDevelopmentTasksShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/progress
         * @summary Прогресс по ИПР для сотруднка
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesDevelopmentTasksProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/questions
         * @summary Список вопросов для ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesDevelopmentTasksQuestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/weights
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesDevelopmentTasksWeights>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/dynamics
         * @summary Динамика доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/scales
         * @summary Шкала распределения доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackExtraQuestionsScales(surveyId: number, groupId?: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackExtraQuestionsScales>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackExtraQuestionsScales(surveyId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/temp
         * @summary Тепловая карта доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/general_questions/answers
         * @summary Комментарии к основным вопросам
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/general_questions/comments
         * @summary Комментарии из тепловая карты
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId, questionId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/general_questions/temp
         * @summary Тепловая карта компетенций
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/general_questions/top
         * @summary Топ результаты
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsTop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/johari
         * @summary Окно Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackJohari(surveyId: number, groupId?: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackJohari>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackJohari(surveyId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/johari/questions
         * @summary Подсказка для Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackJohariQuestions(surveyId: number, groupId?: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackJohariQuestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackJohariQuestions(surveyId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/comments
         * @summary Комментарии из тепловая карты для NPS
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackNpsQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackNpsQuestionsComments(surveyId, questionId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/temp
         * @summary Тепловая карта NPS
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/open_questions/answers
         * @summary Ответы на открытые вопросы
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId: number, groupId?: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/open_questions/list_comments
         * @summary Список ответов по всем опросам и всем вопросам | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds?: Array<number>, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionListComments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/open_questions/top_comments
         * @summary Топ 5 комментов | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds?: Array<number>, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionTopComments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/feedback/xlsx_report
         * @summary Эксель выгрузка одиночного пользователя
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFeedbackXlsxReport(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFeedbackXlsxReport(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/filters
         * @summary Получить данные для фильтров
         * @param {boolean} [reload] сбросить кэш?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsFilters(reload?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFiltersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsFilters(reload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/management/surveys
         * @summary Список доступных опросов
         * @param {string} [userId] ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsManagementSurveys(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementSurveys>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsManagementSurveys(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsManagementXlsxReport(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsManagementXlsxReport(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/surveys
         * @summary Получение списка опросов для аналитики
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsSurveys(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesSurveysPublicAnalyticsIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsSurveys(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов для аналитики
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesExpertListsPublicAnalyticsIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/pages
         * @summary Список страниц для PDF
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsSurveysSurveyIdPages(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsSurveysPages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsSurveysSurveyIdPages(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/statistics/passing/export/xlsx
         * @summary Статистика прохождения выгрузка в xlsx формате
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/tabs
         * @summary Активные табы
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1AnalyticsSurveysSurveyIdTabs(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsSurveysTabs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1AnalyticsSurveysSurveyIdTabs(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/couples/:couple_id/assessment
         * @summary Оценка
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1CouplesCoupleIdAssessment(coupleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAssessmentObjectsPublicShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1CouplesCoupleIdAssessment(coupleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/couples/iam_expert
         * @summary Пары по опросу для эксперта
         * @param {number} surveyId ID опроса
         * @param {Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>} [status] Массив статусов
         * @param {Array<'favorite'>} [without] Исключает пары: favorite - исключить сотрудников с favorite&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1CouplesIamExpert(surveyId: number, status?: Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>, without?: Array<'favorite'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesCouplesPublicExpertIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1CouplesIamExpert(surveyId, status, without, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/demo/assessment
         * @summary Демо Оценки
         * @param {number} surveyId ID Опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1DemoAssessment(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAssessmentObjectsPublicDemo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1DemoAssessment(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/invitees/letter
         * @summary Переменные для шаблона письма внешнему эксперту
         * @param {number} expertListId ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1InviteesLetter(expertListId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesInviteesPublicLetter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1InviteesLetter(expertListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/invitees/search
         * @summary Поиск внешнего эксперта
         * @param {string} email Почта внешнего эксперта, поиск по полному вхождению, регистронезависимый
         * @param {number} expertListId ID группы экспертов
         * @param {string} [userId] ID оцениваемого (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1InviteesSearch(email: string, expertListId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesInviteesPublicSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1InviteesSearch(email, expertListId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1Me(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesUsersPublicMe>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1Me(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/reviews/:survey_id
         * @summary Получение списка оцениваемых и статус их ревью
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ReviewsSurveyId(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesReviewsIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1ReviewsSurveyId(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/reviews/:survey_id/:surveyee_id
         * @summary Получение списка экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesReviewsShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys
         * @summary Получение списка опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1Surveys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesSurveysPublicIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1Surveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/kanban
         * @summary Получение канбана опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysKanban(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesSurveysKanban>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysKanban(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/:survey_id
         * @summary Получения данных об опросе по id
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysSurveyId(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesSurveysPublicShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysSurveyId(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/:survey_id/audiences
         * @summary Поиск аудитории
         * @param {number} surveyId ID Опроса
         * @param {'expert' | 'surveyee'} withoutIam Исключает сотрудников имеющие пары с current_user: expert - исключить сотрудников с кем current_user имеет пары в роли эксперта, surveyee - исключить сотрудников с кем current_user имеет пары в роли оцениваемого
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysSurveyIdAudiences(surveyId: number, withoutIam: 'expert' | 'surveyee', query?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAudiencesPublicSearchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysSurveyIdAudiences(surveyId, withoutIam, query, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов в опросе
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysSurveyIdExpertsLists(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesExpertListsPublicIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysSurveyIdExpertsLists(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/:survey_id/requested_experts
         * @summary Получение списка экспертов, кому отправлен запрос на получение обратной связи
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysSurveyIdRequestedExperts(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesExpertListsPublicRequestedExperts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysSurveyIdRequestedExperts(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/surveys/:survey_id/search-by-experts
         * @summary Поиск экспертов по всей компании
         * @param {number} surveyId ID опроса
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {string} [userId] Текущий пользователь
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1SurveysSurveyIdSearchByExperts(surveyId: number, query?: string, userId?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesSurveysPublicSearchByExperts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1SurveysSurveyIdSearchByExperts(surveyId, query, userId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v1/users/:id
         * @summary Информация по сотруднику
         * @param {string} id ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1UsersId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesUsersPublicShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1UsersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/development_tasks
         * @summary Создание задач ИПР
         * @param {PostV1AnalyticsFeedbackDevelopmentTasks} postV1AnalyticsFeedbackDevelopmentTasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks: PostV1AnalyticsFeedbackDevelopmentTasks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesDevelopmentTasksCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/auto_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/:id/finish
         * @summary Завершение задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id: number, surveyId: number, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id, surveyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/manual_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute} postV1AnalyticsFeedbackDevelopmentTasksManualDistribute 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId: number, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute: PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_competentions
         * @summary Динамика основных вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_indicators
         * @summary Динамика основных вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_competentions
         * @summary Динамика NPS вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_indicators
         * @summary Динамика NPS вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/audiences
         * @summary Поиск сотрудника в аудитории опросов
         * @param {PostV1AnalyticsManagementAudiences} postV1AnalyticsManagementAudiences 
         * @param {number} [surveyId] ID опроса
         * @param {string} [query] Критерий поиска (минимум 1 символ, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences: PostV1AnalyticsManagementAudiences, surveyId?: number, query?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementAudiences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences, surveyId, query, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/dashboards
         * @summary Доски сотрудников для руководителя
         * @param {PostV1AnalyticsManagementDashboards} postV1AnalyticsManagementDashboards 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards: PostV1AnalyticsManagementDashboards, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementDashboards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/difference/heatmap
         * @summary Тепловая карта сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceHeatmap} postV1AnalyticsManagementDifferenceHeatmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap: PostV1AnalyticsManagementDifferenceHeatmap, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementDifferenceHeatmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/difference/xlsx_report
         * @summary Xlsx-отчет сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceXlsxReport} postV1AnalyticsManagementDifferenceXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport: PostV1AnalyticsManagementDifferenceXlsxReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/expert_lists
         * @summary Список групп экпертов
         * @param {PostV1AnalyticsManagementExpertLists} postV1AnalyticsManagementExpertLists 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists: PostV1AnalyticsManagementExpertLists, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementExpertListBySurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/result
         * @summary Список результатов опросов по сотрудникам (с user_ids)
         * @param {PostV1AnalyticsManagementResult} postV1AnalyticsManagementResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementResult(postV1AnalyticsManagementResult: PostV1AnalyticsManagementResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementResultForUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementResult(postV1AnalyticsManagementResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/subordinate_by_ids
         * @summary Сотрудники по id
         * @param {PostV1AnalyticsManagementSubordinateByIds} postV1AnalyticsManagementSubordinateByIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds: PostV1AnalyticsManagementSubordinateByIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementSubordinateByIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/surveys
         * @summary Список доступных опросов (с user_ids)
         * @param {PostV1AnalyticsManagementSurveys} postV1AnalyticsManagementSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys: PostV1AnalyticsManagementSurveys, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsManagementSurveysForUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {PostV1AnalyticsManagementXlsxReport} postV1AnalyticsManagementXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport: PostV1AnalyticsManagementXlsxReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewChooseExperts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewChooseExpertsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewChooseExpertsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewChooseExperts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/send-notification/choose-experts
         * @summary Отправка письма сотрудникам (этап выбра экперта)
         * @param {PostV1AnalyticsOverviewSendNotificationChooseExperts} postV1AnalyticsOverviewSendNotificationChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts: PostV1AnalyticsOverviewSendNotificationChooseExperts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewStartSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewStartSurveyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewStartSurveyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesAnalyticsOverviewStartSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples
         * @summary Создание пары c current_user
         * @param {PostV1Couples} postV1Couples 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Couples(postV1Couples: PostV1Couples, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1Couples(postV1Couples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/by_batch
         * @summary Массовое создание пар
         * @param {PostV1CouplesByBatch} postV1CouplesByBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesByBatch(postV1CouplesByBatch: PostV1CouplesByBatch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesByBatch(postV1CouplesByBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/:couple_id/assessment/answer
         * @summary Сохранение оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdAssessmentAnswer} postV1CouplesCoupleIdAssessmentAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesCoupleIdAssessmentAnswer(coupleId: number, postV1CouplesCoupleIdAssessmentAnswer: PostV1CouplesCoupleIdAssessmentAnswer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesCoupleIdAssessmentAnswer(coupleId, postV1CouplesCoupleIdAssessmentAnswer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/:couple_id/assessment/done
         * @summary Оценка завершена
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesCoupleIdAssessmentDone(coupleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesCoupleIdAssessmentDone(coupleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/:couple_id/move-to-expert-list
         * @summary Перемещение пары для экперта на этапе прохождения
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdMoveToExpertList} postV1CouplesCoupleIdMoveToExpertList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesCoupleIdMoveToExpertList(coupleId: number, postV1CouplesCoupleIdMoveToExpertList: PostV1CouplesCoupleIdMoveToExpertList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesCouplesPublicMoveByExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesCoupleIdMoveToExpertList(coupleId, postV1CouplesCoupleIdMoveToExpertList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/:couple_id/regain
         * @summary Перенос из rejected в pending
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesCoupleIdRegain(coupleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesCoupleIdRegain(coupleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/:couple_id/rejected
         * @summary Отказ от оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdRejected} postV1CouplesCoupleIdRejected 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesCoupleIdRejected(coupleId: number, postV1CouplesCoupleIdRejected: PostV1CouplesCoupleIdRejected, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesCoupleIdRejected(coupleId, postV1CouplesCoupleIdRejected, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/couples/favorite
         * @summary Добавить пары в избранное
         * @param {PostV1CouplesFavorite} postV1CouplesFavorite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CouplesFavorite(postV1CouplesFavorite: PostV1CouplesFavorite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1CouplesFavorite(postV1CouplesFavorite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/invitations
         * @summary Получение JWT по токену приглашения
         * @param {PostV1Invitations} postV1Invitations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Invitations(postV1Invitations: PostV1Invitations, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesInvitationsJwt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1Invitations(postV1Invitations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/invitees
         * @summary Добавление внешнего эксперта к оцениваемому (создание пары)
         * @param {PostV1Invitees} postV1Invitees 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Invitees(postV1Invitees: PostV1Invitees, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1Invitees(postV1Invitees, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/reviews/:survey_id/all
         * @summary Согласование всех экспертов у всех оцениваемых
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ReviewsSurveyIdAll(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1ReviewsSurveyIdAll(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/reviews/:survey_id/approve/:surveyee_id
         * @summary Согласование всех экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ReviewsSurveyIdApproveSurveyeeId(surveyId: number, surveyeeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1ReviewsSurveyIdApproveSurveyeeId(surveyId, surveyeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/reviews/:survey_id/:surveyee_id/create_reviewer
         * @summary Создать у оцениваемого ревью с ответственным
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesReviewsCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId, surveyeeId, reviewerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v1/yandex_gpt/weakness
         * @summary Ручка для генерации и получения плана развития топ-3 проседающих компетенций ГПТ
         * @param {PostV1YandexGptWeakness} postV1YandexGptWeakness 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1YandexGptWeakness(postV1YandexGptWeakness: PostV1YandexGptWeakness, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EntitiesYandexGptWeakness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1YandexGptWeakness(postV1YandexGptWeakness, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /v1/analytics/feedback/development_tasks/:id
         * @summary Обновление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {PutV1AnalyticsFeedbackDevelopmentTasksId} putV1AnalyticsFeedbackDevelopmentTasksId 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, putV1AnalyticsFeedbackDevelopmentTasksId: PutV1AnalyticsFeedbackDevelopmentTasksId, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, putV1AnalyticsFeedbackDevelopmentTasksId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /v1/reviews/:survey_id/:surveyee_id/update_reviewer
         * @summary Заменить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId, surveyeeId, reviewerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * DELETE /v1/analytics/feedback/development_tasks/:id
         * @summary Удаление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /v1/couples/:couple_id
         * @summary Удаление пары (выбор экспертов)
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CouplesCoupleId(coupleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteV1CouplesCoupleId(coupleId, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id
         * @summary Удаление эксперта у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} expertId ID эксперта
         * @param {number} [expertListId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, expertId: string, expertListId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, expertId, expertListId, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /v1/reviews/:survey_id/:surveyee_id/delete_reviewer
         * @summary Удалить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/average_rate
         * @summary Средний бал
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {boolean} [withoutSelf] Без учета самооценки по всем группам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackAverageRate(surveyId: number, groupId?: number, userId?: string, withoutSelf?: boolean, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackAverageRate> {
            return localVarFp.getV1AnalyticsFeedbackAverageRate(surveyId, groupId, userId, withoutSelf, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/behavior/answers
         * @summary Поведение варианты ответов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackBehaviorAnswers(surveyId: number, userId?: string, groupId?: number, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackBehaviorQuestionsAnswers> {
            return localVarFp.getV1AnalyticsFeedbackBehaviorAnswers(surveyId, userId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/behavior/dynamics
         * @summary Динамика поведенческих вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {number} [groupId] ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackBehaviorDynamics(surveyId: number, userId?: string, groupId?: number, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackBehaviorQuestionsDynamics> {
            return localVarFp.getV1AnalyticsFeedbackBehaviorDynamics(surveyId, userId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/destructors/dynamics
         * @summary Динамика деструкторов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDestructorsDynamics(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackDestructorsDynamics> {
            return localVarFp.getV1AnalyticsFeedbackDestructorsDynamics(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/destructors/survey
         * @summary Деструкторы в текущем опросе
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDestructorsSurvey(surveyId: number, groupId?: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackDestructorsSurvey> {
            return localVarFp.getV1AnalyticsFeedbackDestructorsSurvey(surveyId, groupId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/development_tasks
         * @summary Список задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasks(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackDevelopmentTasksIndex> {
            return localVarFp.getV1AnalyticsFeedbackDevelopmentTasks(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/:id
         * @summary Просмотр задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesDevelopmentTasksShow> {
            return localVarFp.getV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/progress
         * @summary Прогресс по ИПР для сотруднка
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesDevelopmentTasksProgress> {
            return localVarFp.getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/questions
         * @summary Список вопросов для ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesDevelopmentTasksQuestions> {
            return localVarFp.getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/development_tasks/weights
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesDevelopmentTasksWeights> {
            return localVarFp.getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/dynamics
         * @summary Динамика доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackExtraQuestionsDynamics> {
            return localVarFp.getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/scales
         * @summary Шкала распределения доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsScales(surveyId: number, groupId?: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackExtraQuestionsScales> {
            return localVarFp.getV1AnalyticsFeedbackExtraQuestionsScales(surveyId, groupId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/extra_questions/temp
         * @summary Тепловая карта доп. вопросов
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp> {
            return localVarFp.getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/general_questions/answers
         * @summary Комментарии к основным вопросам
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers> {
            return localVarFp.getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/general_questions/comments
         * @summary Комментарии из тепловая карты
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsComment> {
            return localVarFp.getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId, questionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/general_questions/temp
         * @summary Тепловая карта компетенций
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp> {
            return localVarFp.getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/general_questions/top
         * @summary Топ результаты
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsTop> {
            return localVarFp.getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/johari
         * @summary Окно Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackJohari(surveyId: number, groupId?: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackJohari> {
            return localVarFp.getV1AnalyticsFeedbackJohari(surveyId, groupId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/johari/questions
         * @summary Подсказка для Джохари
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackJohariQuestions(surveyId: number, groupId?: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackJohariQuestions> {
            return localVarFp.getV1AnalyticsFeedbackJohariQuestions(surveyId, groupId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/comments
         * @summary Комментарии из тепловая карты для NPS
         * @param {number} surveyId ID опроса
         * @param {number} questionId ID вопроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackNpsQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsComment> {
            return localVarFp.getV1AnalyticsFeedbackNpsQuestionsComments(surveyId, questionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/nps_questions/temp
         * @summary Тепловая карта NPS
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackQuestionsTemp> {
            return localVarFp.getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/open_questions/answers
         * @summary Ответы на открытые вопросы
         * @param {number} surveyId ID опроса
         * @param {number} [groupId] ID группы экспертов
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId: number, groupId?: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionAnswers> {
            return localVarFp.getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId, groupId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/open_questions/list_comments
         * @summary Список ответов по всем опросам и всем вопросам | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds?: Array<number>, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionListComments> {
            return localVarFp.getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/open_questions/top_comments
         * @summary Топ 5 комментов | Открытые вопросы
         * @param {Array<number>} [surveyIds] ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds?: Array<number>, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackOpenQuestionTopComments> {
            return localVarFp.getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/feedback/xlsx_report
         * @summary Эксель выгрузка одиночного пользователя
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFeedbackXlsxReport(surveyId: number, userId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getV1AnalyticsFeedbackXlsxReport(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/filters
         * @summary Получить данные для фильтров
         * @param {boolean} [reload] сбросить кэш?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsFilters(reload?: boolean, options?: any): AxiosPromise<V1EntitiesAnalyticsFiltersList> {
            return localVarFp.getV1AnalyticsFilters(reload, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/management/surveys
         * @summary Список доступных опросов
         * @param {string} [userId] ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsManagementSurveys(userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementSurveys> {
            return localVarFp.getV1AnalyticsManagementSurveys(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsManagementXlsxReport(surveyId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getV1AnalyticsManagementXlsxReport(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/surveys
         * @summary Получение списка опросов для аналитики
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveys(userId?: string, options?: any): AxiosPromise<V1EntitiesSurveysPublicAnalyticsIndex> {
            return localVarFp.getV1AnalyticsSurveys(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов для аналитики
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesExpertListsPublicAnalyticsIndex> {
            return localVarFp.getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/pages
         * @summary Список страниц для PDF
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdPages(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsSurveysPages> {
            return localVarFp.getV1AnalyticsSurveysSurveyIdPages(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/statistics/passing/export/xlsx
         * @summary Статистика прохождения выгрузка в xlsx формате
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/analytics/surveys/:survey_id/tabs
         * @summary Активные табы
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1AnalyticsSurveysSurveyIdTabs(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesAnalyticsSurveysTabs> {
            return localVarFp.getV1AnalyticsSurveysSurveyIdTabs(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/couples/:couple_id/assessment
         * @summary Оценка
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CouplesCoupleIdAssessment(coupleId: number, options?: any): AxiosPromise<V1EntitiesAssessmentObjectsPublicShow> {
            return localVarFp.getV1CouplesCoupleIdAssessment(coupleId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/couples/iam_expert
         * @summary Пары по опросу для эксперта
         * @param {number} surveyId ID опроса
         * @param {Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>} [status] Массив статусов
         * @param {Array<'favorite'>} [without] Исключает пары: favorite - исключить сотрудников с favorite&#x3D;true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CouplesIamExpert(surveyId: number, status?: Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>, without?: Array<'favorite'>, options?: any): AxiosPromise<V1EntitiesCouplesPublicExpertIndex> {
            return localVarFp.getV1CouplesIamExpert(surveyId, status, without, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/demo/assessment
         * @summary Демо Оценки
         * @param {number} surveyId ID Опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1DemoAssessment(surveyId: number, options?: any): AxiosPromise<V1EntitiesAssessmentObjectsPublicDemo> {
            return localVarFp.getV1DemoAssessment(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/invitees/letter
         * @summary Переменные для шаблона письма внешнему эксперту
         * @param {number} expertListId ID группы экспертов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1InviteesLetter(expertListId: number, options?: any): AxiosPromise<V1EntitiesInviteesPublicLetter> {
            return localVarFp.getV1InviteesLetter(expertListId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/invitees/search
         * @summary Поиск внешнего эксперта
         * @param {string} email Почта внешнего эксперта, поиск по полному вхождению, регистронезависимый
         * @param {number} expertListId ID группы экспертов
         * @param {string} [userId] ID оцениваемого (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1InviteesSearch(email: string, expertListId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesInviteesPublicSearch> {
            return localVarFp.getV1InviteesSearch(email, expertListId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Me(options?: any): AxiosPromise<V1EntitiesUsersPublicMe> {
            return localVarFp.getV1Me(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/reviews/:survey_id
         * @summary Получение списка оцениваемых и статус их ревью
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ReviewsSurveyId(surveyId: number, options?: any): AxiosPromise<V1EntitiesReviewsIndex> {
            return localVarFp.getV1ReviewsSurveyId(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/reviews/:survey_id/:surveyee_id
         * @summary Получение списка экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, options?: any): AxiosPromise<V1EntitiesReviewsShow> {
            return localVarFp.getV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys
         * @summary Получение списка опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Surveys(options?: any): AxiosPromise<V1EntitiesSurveysPublicIndex> {
            return localVarFp.getV1Surveys(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/kanban
         * @summary Получение канбана опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysKanban(options?: any): AxiosPromise<V1EntitiesSurveysKanban> {
            return localVarFp.getV1SurveysKanban(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/:survey_id
         * @summary Получения данных об опросе по id
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyId(surveyId: number, userId?: string, options?: any): AxiosPromise<V1EntitiesSurveysPublicShow> {
            return localVarFp.getV1SurveysSurveyId(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/:survey_id/audiences
         * @summary Поиск аудитории
         * @param {number} surveyId ID Опроса
         * @param {'expert' | 'surveyee'} withoutIam Исключает сотрудников имеющие пары с current_user: expert - исключить сотрудников с кем current_user имеет пары в роли эксперта, surveyee - исключить сотрудников с кем current_user имеет пары в роли оцениваемого
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdAudiences(surveyId: number, withoutIam: 'expert' | 'surveyee', query?: string, page?: number, limit?: number, options?: any): AxiosPromise<V1EntitiesAudiencesPublicSearchIndex> {
            return localVarFp.getV1SurveysSurveyIdAudiences(surveyId, withoutIam, query, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/:survey_id/experts_lists
         * @summary Получение списка групп экспертов в опросе
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdExpertsLists(surveyId: number, options?: any): AxiosPromise<V1EntitiesExpertListsPublicIndex> {
            return localVarFp.getV1SurveysSurveyIdExpertsLists(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/:survey_id/requested_experts
         * @summary Получение списка экспертов, кому отправлен запрос на получение обратной связи
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdRequestedExperts(surveyId: number, options?: any): AxiosPromise<V1EntitiesExpertListsPublicRequestedExperts> {
            return localVarFp.getV1SurveysSurveyIdRequestedExperts(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/surveys/:survey_id/search-by-experts
         * @summary Поиск экспертов по всей компании
         * @param {number} surveyId ID опроса
         * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
         * @param {string} [userId] Текущий пользователь
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1SurveysSurveyIdSearchByExperts(surveyId: number, query?: string, userId?: string, page?: number, limit?: number, options?: any): AxiosPromise<V1EntitiesSurveysPublicSearchByExperts> {
            return localVarFp.getV1SurveysSurveyIdSearchByExperts(surveyId, query, userId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v1/users/:id
         * @summary Информация по сотруднику
         * @param {string} id ID пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UsersId(id: string, options?: any): AxiosPromise<V1EntitiesUsersPublicShow> {
            return localVarFp.getV1UsersId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/development_tasks
         * @summary Создание задач ИПР
         * @param {PostV1AnalyticsFeedbackDevelopmentTasks} postV1AnalyticsFeedbackDevelopmentTasks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks: PostV1AnalyticsFeedbackDevelopmentTasks, options?: any): AxiosPromise<V1EntitiesDevelopmentTasksCreate> {
            return localVarFp.postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/auto_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId: number, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/:id/finish
         * @summary Завершение задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id: number, surveyId: number, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id, surveyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/development_tasks/manual_distribute
         * @summary Вес задач ИПР
         * @param {number} surveyId ID опроса
         * @param {PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute} postV1AnalyticsFeedbackDevelopmentTasksManualDistribute 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId: number, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute: PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_competentions
         * @summary Динамика основных вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions> {
            return localVarFp.postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/general_questions/dynamics_by_indicators
         * @summary Динамика основных вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators> {
            return localVarFp.postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_competentions
         * @summary Динамика NPS вопросов по индикаторам (группы вопросов)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByCompetentions> {
            return localVarFp.postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/feedback/nps_questions/dynamics_by_indicators
         * @summary Динамика NPS вопросов по индикаторам (вопросы)
         * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options?: any): AxiosPromise<V1EntitiesAnalyticsFeedbackNpsQuestionsDynamicsByIndicators> {
            return localVarFp.postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/audiences
         * @summary Поиск сотрудника в аудитории опросов
         * @param {PostV1AnalyticsManagementAudiences} postV1AnalyticsManagementAudiences 
         * @param {number} [surveyId] ID опроса
         * @param {string} [query] Критерий поиска (минимум 1 символ, иначе не учитывается)
         * @param {number} [page] Выбранная страница
         * @param {number} [limit] Количество элементов на страницу
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences: PostV1AnalyticsManagementAudiences, surveyId?: number, query?: string, page?: number, limit?: number, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementAudiences> {
            return localVarFp.postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences, surveyId, query, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/dashboards
         * @summary Доски сотрудников для руководителя
         * @param {PostV1AnalyticsManagementDashboards} postV1AnalyticsManagementDashboards 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards: PostV1AnalyticsManagementDashboards, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementDashboards> {
            return localVarFp.postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/difference/heatmap
         * @summary Тепловая карта сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceHeatmap} postV1AnalyticsManagementDifferenceHeatmap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap: PostV1AnalyticsManagementDifferenceHeatmap, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementDifferenceHeatmap> {
            return localVarFp.postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/difference/xlsx_report
         * @summary Xlsx-отчет сравнения сотрудников
         * @param {PostV1AnalyticsManagementDifferenceXlsxReport} postV1AnalyticsManagementDifferenceXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport: PostV1AnalyticsManagementDifferenceXlsxReport, options?: any): AxiosPromise<any> {
            return localVarFp.postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/expert_lists
         * @summary Список групп экпертов
         * @param {PostV1AnalyticsManagementExpertLists} postV1AnalyticsManagementExpertLists 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists: PostV1AnalyticsManagementExpertLists, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementExpertListBySurvey> {
            return localVarFp.postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/result
         * @summary Список результатов опросов по сотрудникам (с user_ids)
         * @param {PostV1AnalyticsManagementResult} postV1AnalyticsManagementResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementResult(postV1AnalyticsManagementResult: PostV1AnalyticsManagementResult, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementResultForUsers> {
            return localVarFp.postV1AnalyticsManagementResult(postV1AnalyticsManagementResult, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/subordinate_by_ids
         * @summary Сотрудники по id
         * @param {PostV1AnalyticsManagementSubordinateByIds} postV1AnalyticsManagementSubordinateByIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds: PostV1AnalyticsManagementSubordinateByIds, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementSubordinateByIds> {
            return localVarFp.postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/surveys
         * @summary Список доступных опросов (с user_ids)
         * @param {PostV1AnalyticsManagementSurveys} postV1AnalyticsManagementSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys: PostV1AnalyticsManagementSurveys, options?: any): AxiosPromise<V1EntitiesAnalyticsManagementSurveysForUsers> {
            return localVarFp.postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/management/xlsx_report
         * @summary Эксель выгрузка по сотрудникам
         * @param {PostV1AnalyticsManagementXlsxReport} postV1AnalyticsManagementXlsxReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport: PostV1AnalyticsManagementXlsxReport, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewChooseExperts> {
            return localVarFp.postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewChooseExpertsList> {
            return localVarFp.postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/choose-experts-list
         * @summary Список с пагинацией сотрудников которые выбрали N экспертов
         * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewChooseExpertsList> {
            return localVarFp.postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/choose-experts
         * @summary Статистика по всему опросу (этап выбор экпертов)
         * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewChooseExperts> {
            return localVarFp.postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/send-notification/choose-experts
         * @summary Отправка письма сотрудникам (этап выбра экперта)
         * @param {PostV1AnalyticsOverviewSendNotificationChooseExperts} postV1AnalyticsOverviewSendNotificationChooseExperts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts: PostV1AnalyticsOverviewSendNotificationChooseExperts, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/send-notification/start-survey
         * @summary Отправка письма сотрудникам (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any): AxiosPromise<void> {
            return localVarFp.postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewStartSurvey> {
            return localVarFp.postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewStartSurveyList> {
            return localVarFp.postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/start-survey-list
         * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewStartSurveyList> {
            return localVarFp.postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/analytics/overview/start-survey
         * @summary Статистика по всему опросу (этап прохождение опроса)
         * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any): AxiosPromise<V1EntitiesAnalyticsOverviewStartSurvey> {
            return localVarFp.postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples
         * @summary Создание пары c current_user
         * @param {PostV1Couples} postV1Couples 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Couples(postV1Couples: PostV1Couples, options?: any): AxiosPromise<void> {
            return localVarFp.postV1Couples(postV1Couples, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/by_batch
         * @summary Массовое создание пар
         * @param {PostV1CouplesByBatch} postV1CouplesByBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesByBatch(postV1CouplesByBatch: PostV1CouplesByBatch, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesByBatch(postV1CouplesByBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/:couple_id/assessment/answer
         * @summary Сохранение оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdAssessmentAnswer} postV1CouplesCoupleIdAssessmentAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdAssessmentAnswer(coupleId: number, postV1CouplesCoupleIdAssessmentAnswer: PostV1CouplesCoupleIdAssessmentAnswer, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesCoupleIdAssessmentAnswer(coupleId, postV1CouplesCoupleIdAssessmentAnswer, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/:couple_id/assessment/done
         * @summary Оценка завершена
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdAssessmentDone(coupleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesCoupleIdAssessmentDone(coupleId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/:couple_id/move-to-expert-list
         * @summary Перемещение пары для экперта на этапе прохождения
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdMoveToExpertList} postV1CouplesCoupleIdMoveToExpertList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdMoveToExpertList(coupleId: number, postV1CouplesCoupleIdMoveToExpertList: PostV1CouplesCoupleIdMoveToExpertList, options?: any): AxiosPromise<V1EntitiesCouplesPublicMoveByExpert> {
            return localVarFp.postV1CouplesCoupleIdMoveToExpertList(coupleId, postV1CouplesCoupleIdMoveToExpertList, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/:couple_id/regain
         * @summary Перенос из rejected в pending
         * @param {number} coupleId ID пары
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdRegain(coupleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesCoupleIdRegain(coupleId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/:couple_id/rejected
         * @summary Отказ от оценки
         * @param {number} coupleId ID пары
         * @param {PostV1CouplesCoupleIdRejected} postV1CouplesCoupleIdRejected 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesCoupleIdRejected(coupleId: number, postV1CouplesCoupleIdRejected: PostV1CouplesCoupleIdRejected, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesCoupleIdRejected(coupleId, postV1CouplesCoupleIdRejected, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/couples/favorite
         * @summary Добавить пары в избранное
         * @param {PostV1CouplesFavorite} postV1CouplesFavorite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CouplesFavorite(postV1CouplesFavorite: PostV1CouplesFavorite, options?: any): AxiosPromise<void> {
            return localVarFp.postV1CouplesFavorite(postV1CouplesFavorite, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/invitations
         * @summary Получение JWT по токену приглашения
         * @param {PostV1Invitations} postV1Invitations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Invitations(postV1Invitations: PostV1Invitations, options?: any): AxiosPromise<V1EntitiesInvitationsJwt> {
            return localVarFp.postV1Invitations(postV1Invitations, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/invitees
         * @summary Добавление внешнего эксперта к оцениваемому (создание пары)
         * @param {PostV1Invitees} postV1Invitees 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Invitees(postV1Invitees: PostV1Invitees, options?: any): AxiosPromise<void> {
            return localVarFp.postV1Invitees(postV1Invitees, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/reviews/:survey_id/all
         * @summary Согласование всех экспертов у всех оцениваемых
         * @param {number} surveyId ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdAll(surveyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postV1ReviewsSurveyIdAll(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/reviews/:survey_id/approve/:surveyee_id
         * @summary Согласование всех экспертов у оцениваемого
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdApproveSurveyeeId(surveyId: number, surveyeeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.postV1ReviewsSurveyIdApproveSurveyeeId(surveyId, surveyeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/reviews/:survey_id/:surveyee_id/create_reviewer
         * @summary Создать у оцениваемого ревью с ответственным
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): AxiosPromise<V1EntitiesReviewsCreate> {
            return localVarFp.postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v1/yandex_gpt/weakness
         * @summary Ручка для генерации и получения плана развития топ-3 проседающих компетенций ГПТ
         * @param {PostV1YandexGptWeakness} postV1YandexGptWeakness 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1YandexGptWeakness(postV1YandexGptWeakness: PostV1YandexGptWeakness, options?: any): AxiosPromise<V1EntitiesYandexGptWeakness> {
            return localVarFp.postV1YandexGptWeakness(postV1YandexGptWeakness, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /v1/analytics/feedback/development_tasks/:id
         * @summary Обновление задачи ИПР
         * @param {number} id ID задачи ИПР
         * @param {number} surveyId ID опроса
         * @param {PutV1AnalyticsFeedbackDevelopmentTasksId} putV1AnalyticsFeedbackDevelopmentTasksId 
         * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, putV1AnalyticsFeedbackDevelopmentTasksId: PutV1AnalyticsFeedbackDevelopmentTasksId, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.putV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, putV1AnalyticsFeedbackDevelopmentTasksId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /v1/reviews/:survey_id/:surveyee_id/update_reviewer
         * @summary Заменить у оцениваемого ответственного в ревью
         * @param {number} surveyId ID опроса
         * @param {string} surveyeeId ID оцениваемого
         * @param {string} reviewerId ID ответственного
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * DELETE /v1/analytics/feedback/development_tasks/:id
     * @summary Удаление задачи ИПР
     * @param {number} id ID задачи ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /v1/couples/:couple_id
     * @summary Удаление пары (выбор экспертов)
     * @param {number} coupleId ID пары
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteV1CouplesCoupleId(coupleId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteV1CouplesCoupleId(coupleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /v1/reviews/:survey_id/:surveyee_id
     * @summary Удаление эксперта у оцениваемого
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {string} expertId ID эксперта
     * @param {number} [expertListId] ID группы экспертов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, expertId: string, expertListId?: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, expertId, expertListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /v1/reviews/:survey_id/:surveyee_id/delete_reviewer
     * @summary Удалить у оцениваемого ответственного в ревью
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {string} reviewerId ID ответственного
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteV1ReviewsSurveyIdSurveyeeIdDeleteReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/average_rate
     * @summary Средний бал
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {boolean} [withoutSelf] Без учета самооценки по всем группам
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackAverageRate(surveyId: number, groupId?: number, userId?: string, withoutSelf?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackAverageRate(surveyId, groupId, userId, withoutSelf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/behavior/answers
     * @summary Поведение варианты ответов
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {number} [groupId] ID группы экспертов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackBehaviorAnswers(surveyId: number, userId?: string, groupId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackBehaviorAnswers(surveyId, userId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/behavior/dynamics
     * @summary Динамика поведенческих вопросов
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {number} [groupId] ID группы экспертов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackBehaviorDynamics(surveyId: number, userId?: string, groupId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackBehaviorDynamics(surveyId, userId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/destructors/dynamics
     * @summary Динамика деструкторов
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDestructorsDynamics(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDestructorsDynamics(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/destructors/survey
     * @summary Деструкторы в текущем опросе
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDestructorsSurvey(surveyId: number, groupId?: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDestructorsSurvey(surveyId, groupId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/development_tasks
     * @summary Список задач ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDevelopmentTasks(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDevelopmentTasks(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/development_tasks/:id
     * @summary Просмотр задачи ИПР
     * @param {number} id ID задачи ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/development_tasks/progress
     * @summary Прогресс по ИПР для сотруднка
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDevelopmentTasksProgress(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/development_tasks/questions
     * @summary Список вопросов для ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDevelopmentTasksQuestions(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/development_tasks/weights
     * @summary Вес задач ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackDevelopmentTasksWeights(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/extra_questions/dynamics
     * @summary Динамика доп. вопросов
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackExtraQuestionsDynamics(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/extra_questions/scales
     * @summary Шкала распределения доп. вопросов
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackExtraQuestionsScales(surveyId: number, groupId?: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackExtraQuestionsScales(surveyId, groupId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/extra_questions/temp
     * @summary Тепловая карта доп. вопросов
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackExtraQuestionsTemp(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/general_questions/answers
     * @summary Комментарии к основным вопросам
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackGeneralQuestionsAnswers(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/general_questions/comments
     * @summary Комментарии из тепловая карты
     * @param {number} surveyId ID опроса
     * @param {number} questionId ID вопроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackGeneralQuestionsComments(surveyId, questionId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/general_questions/temp
     * @summary Тепловая карта компетенций
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackGeneralQuestionsTemp(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/general_questions/top
     * @summary Топ результаты
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackGeneralQuestionsTop(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/johari
     * @summary Окно Джохари
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackJohari(surveyId: number, groupId?: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackJohari(surveyId, groupId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/johari/questions
     * @summary Подсказка для Джохари
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackJohariQuestions(surveyId: number, groupId?: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackJohariQuestions(surveyId, groupId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/nps_questions/comments
     * @summary Комментарии из тепловая карты для NPS
     * @param {number} surveyId ID опроса
     * @param {number} questionId ID вопроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackNpsQuestionsComments(surveyId: number, questionId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackNpsQuestionsComments(surveyId, questionId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/nps_questions/temp
     * @summary Тепловая карта NPS
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackNpsQuestionsTemp(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/open_questions/answers
     * @summary Ответы на открытые вопросы
     * @param {number} surveyId ID опроса
     * @param {number} [groupId] ID группы экспертов
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId: number, groupId?: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackOpenQuestionsAnswers(surveyId, groupId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/open_questions/list_comments
     * @summary Список ответов по всем опросам и всем вопросам | Открытые вопросы
     * @param {Array<number>} [surveyIds] ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds?: Array<number>, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackOpenQuestionsListComments(surveyIds, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/open_questions/top_comments
     * @summary Топ 5 комментов | Открытые вопросы
     * @param {Array<number>} [surveyIds] ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds?: Array<number>, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackOpenQuestionsTopComments(surveyIds, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/feedback/xlsx_report
     * @summary Эксель выгрузка одиночного пользователя
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFeedbackXlsxReport(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFeedbackXlsxReport(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/filters
     * @summary Получить данные для фильтров
     * @param {boolean} [reload] сбросить кэш?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsFilters(reload?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsFilters(reload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/management/surveys
     * @summary Список доступных опросов
     * @param {string} [userId] ID пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsManagementSurveys(userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsManagementSurveys(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/management/xlsx_report
     * @summary Эксель выгрузка по сотрудникам
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsManagementXlsxReport(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsManagementXlsxReport(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/surveys
     * @summary Получение списка опросов для аналитики
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsSurveys(userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsSurveys(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/surveys/:survey_id/experts_lists
     * @summary Получение списка групп экспертов для аналитики
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsSurveysSurveyIdExpertsLists(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/surveys/:survey_id/pages
     * @summary Список страниц для PDF
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsSurveysSurveyIdPages(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsSurveysSurveyIdPages(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/surveys/:survey_id/statistics/passing/export/xlsx
     * @summary Статистика прохождения выгрузка в xlsx формате
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/analytics/surveys/:survey_id/tabs
     * @summary Активные табы
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1AnalyticsSurveysSurveyIdTabs(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1AnalyticsSurveysSurveyIdTabs(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/couples/:couple_id/assessment
     * @summary Оценка
     * @param {number} coupleId ID пары
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1CouplesCoupleIdAssessment(coupleId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1CouplesCoupleIdAssessment(coupleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/couples/iam_expert
     * @summary Пары по опросу для эксперта
     * @param {number} surveyId ID опроса
     * @param {Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>} [status] Массив статусов
     * @param {Array<'favorite'>} [without] Исключает пары: favorite - исключить сотрудников с favorite&#x3D;true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1CouplesIamExpert(surveyId: number, status?: Array<'init' | 'pending' | 'running' | 'done' | 'rejected' | 'archived'>, without?: Array<'favorite'>, options?: any) {
        return DefaultApiFp(this.configuration).getV1CouplesIamExpert(surveyId, status, without, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/demo/assessment
     * @summary Демо Оценки
     * @param {number} surveyId ID Опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1DemoAssessment(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1DemoAssessment(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/invitees/letter
     * @summary Переменные для шаблона письма внешнему эксперту
     * @param {number} expertListId ID группы экспертов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1InviteesLetter(expertListId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1InviteesLetter(expertListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/invitees/search
     * @summary Поиск внешнего эксперта
     * @param {string} email Почта внешнего эксперта, поиск по полному вхождению, регистронезависимый
     * @param {number} expertListId ID группы экспертов
     * @param {string} [userId] ID оцениваемого (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1InviteesSearch(email: string, expertListId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1InviteesSearch(email, expertListId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/me
     * @summary Информация о текущем пользователе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1Me(options?: any) {
        return DefaultApiFp(this.configuration).getV1Me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/reviews/:survey_id
     * @summary Получение списка оцениваемых и статус их ревью
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1ReviewsSurveyId(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1ReviewsSurveyId(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/reviews/:survey_id/:surveyee_id
     * @summary Получение списка экспертов у оцениваемого
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1ReviewsSurveyIdSurveyeeId(surveyId: number, surveyeeId: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1ReviewsSurveyIdSurveyeeId(surveyId, surveyeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys
     * @summary Получение списка опросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1Surveys(options?: any) {
        return DefaultApiFp(this.configuration).getV1Surveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/kanban
     * @summary Получение канбана опросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysKanban(options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysKanban(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/:survey_id
     * @summary Получения данных об опросе по id
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysSurveyId(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysSurveyId(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/:survey_id/audiences
     * @summary Поиск аудитории
     * @param {number} surveyId ID Опроса
     * @param {'expert' | 'surveyee'} withoutIam Исключает сотрудников имеющие пары с current_user: expert - исключить сотрудников с кем current_user имеет пары в роли эксперта, surveyee - исключить сотрудников с кем current_user имеет пары в роли оцениваемого
     * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
     * @param {number} [page] Выбранная страница
     * @param {number} [limit] Количество элементов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysSurveyIdAudiences(surveyId: number, withoutIam: 'expert' | 'surveyee', query?: string, page?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysSurveyIdAudiences(surveyId, withoutIam, query, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/:survey_id/experts_lists
     * @summary Получение списка групп экспертов в опросе
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysSurveyIdExpertsLists(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysSurveyIdExpertsLists(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/:survey_id/requested_experts
     * @summary Получение списка экспертов, кому отправлен запрос на получение обратной связи
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysSurveyIdRequestedExperts(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysSurveyIdRequestedExperts(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/surveys/:survey_id/search-by-experts
     * @summary Поиск экспертов по всей компании
     * @param {number} surveyId ID опроса
     * @param {string} [query] Критерий поиска (минимум 3 символа, иначе не учитывается)
     * @param {string} [userId] Текущий пользователь
     * @param {number} [page] Выбранная страница
     * @param {number} [limit] Количество элементов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1SurveysSurveyIdSearchByExperts(surveyId: number, query?: string, userId?: string, page?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).getV1SurveysSurveyIdSearchByExperts(surveyId, query, userId, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v1/users/:id
     * @summary Информация по сотруднику
     * @param {string} id ID пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getV1UsersId(id: string, options?: any) {
        return DefaultApiFp(this.configuration).getV1UsersId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/development_tasks
     * @summary Создание задач ИПР
     * @param {PostV1AnalyticsFeedbackDevelopmentTasks} postV1AnalyticsFeedbackDevelopmentTasks 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks: PostV1AnalyticsFeedbackDevelopmentTasks, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackDevelopmentTasks(postV1AnalyticsFeedbackDevelopmentTasks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/development_tasks/auto_distribute
     * @summary Вес задач ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackDevelopmentTasksAutoDistribute(surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/development_tasks/:id/finish
     * @summary Завершение задачи ИПР
     * @param {number} id ID задачи ИПР
     * @param {number} surveyId ID опроса
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id: number, surveyId: number, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackDevelopmentTasksIdFinish(id, surveyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/development_tasks/manual_distribute
     * @summary Вес задач ИПР
     * @param {number} surveyId ID опроса
     * @param {PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute} postV1AnalyticsFeedbackDevelopmentTasksManualDistribute 
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId: number, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute: PostV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackDevelopmentTasksManualDistribute(surveyId, postV1AnalyticsFeedbackDevelopmentTasksManualDistribute, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/general_questions/dynamics_by_competentions
     * @summary Динамика основных вопросов по индикаторам (группы вопросов)
     * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/general_questions/dynamics_by_indicators
     * @summary Динамика основных вопросов по индикаторам (вопросы)
     * @param {PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackGeneralQuestionsDynamicsByIndicators, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/nps_questions/dynamics_by_competentions
     * @summary Динамика NPS вопросов по индикаторам (группы вопросов)
     * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions} postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions(postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/feedback/nps_questions/dynamics_by_indicators
     * @summary Динамика NPS вопросов по индикаторам (вопросы)
     * @param {PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators} postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators: PostV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators(postV1AnalyticsFeedbackNpsQuestionsDynamicsByIndicators, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/audiences
     * @summary Поиск сотрудника в аудитории опросов
     * @param {PostV1AnalyticsManagementAudiences} postV1AnalyticsManagementAudiences 
     * @param {number} [surveyId] ID опроса
     * @param {string} [query] Критерий поиска (минимум 1 символ, иначе не учитывается)
     * @param {number} [page] Выбранная страница
     * @param {number} [limit] Количество элементов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences: PostV1AnalyticsManagementAudiences, surveyId?: number, query?: string, page?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementAudiences(postV1AnalyticsManagementAudiences, surveyId, query, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/dashboards
     * @summary Доски сотрудников для руководителя
     * @param {PostV1AnalyticsManagementDashboards} postV1AnalyticsManagementDashboards 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards: PostV1AnalyticsManagementDashboards, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementDashboards(postV1AnalyticsManagementDashboards, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/difference/heatmap
     * @summary Тепловая карта сравнения сотрудников
     * @param {PostV1AnalyticsManagementDifferenceHeatmap} postV1AnalyticsManagementDifferenceHeatmap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap: PostV1AnalyticsManagementDifferenceHeatmap, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementDifferenceHeatmap(postV1AnalyticsManagementDifferenceHeatmap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/difference/xlsx_report
     * @summary Xlsx-отчет сравнения сотрудников
     * @param {PostV1AnalyticsManagementDifferenceXlsxReport} postV1AnalyticsManagementDifferenceXlsxReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport: PostV1AnalyticsManagementDifferenceXlsxReport, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementDifferenceXlsxReport(postV1AnalyticsManagementDifferenceXlsxReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/expert_lists
     * @summary Список групп экпертов
     * @param {PostV1AnalyticsManagementExpertLists} postV1AnalyticsManagementExpertLists 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists: PostV1AnalyticsManagementExpertLists, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementExpertLists(postV1AnalyticsManagementExpertLists, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/result
     * @summary Список результатов опросов по сотрудникам (с user_ids)
     * @param {PostV1AnalyticsManagementResult} postV1AnalyticsManagementResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementResult(postV1AnalyticsManagementResult: PostV1AnalyticsManagementResult, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementResult(postV1AnalyticsManagementResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/subordinate_by_ids
     * @summary Сотрудники по id
     * @param {PostV1AnalyticsManagementSubordinateByIds} postV1AnalyticsManagementSubordinateByIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds: PostV1AnalyticsManagementSubordinateByIds, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementSubordinateByIds(postV1AnalyticsManagementSubordinateByIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/surveys
     * @summary Список доступных опросов (с user_ids)
     * @param {PostV1AnalyticsManagementSurveys} postV1AnalyticsManagementSurveys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys: PostV1AnalyticsManagementSurveys, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementSurveys(postV1AnalyticsManagementSurveys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/management/xlsx_report
     * @summary Эксель выгрузка по сотрудникам
     * @param {PostV1AnalyticsManagementXlsxReport} postV1AnalyticsManagementXlsxReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport: PostV1AnalyticsManagementXlsxReport, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsManagementXlsxReport(postV1AnalyticsManagementXlsxReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/choose-experts
     * @summary Статистика по всему опросу (этап выбор экпертов)
     * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewChooseExperts(postV1AnalyticsOverviewChooseExperts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/choose-experts-list
     * @summary Список с пагинацией сотрудников которые выбрали N экспертов
     * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewChooseExpertsList(postV1AnalyticsOverviewChooseExpertsList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/choose-experts-list
     * @summary Список с пагинацией сотрудников которые выбрали N экспертов
     * @param {PostV1AnalyticsOverviewChooseExpertsList} postV1AnalyticsOverviewChooseExpertsList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList: PostV1AnalyticsOverviewChooseExpertsList, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewChooseExpertsList_1(postV1AnalyticsOverviewChooseExpertsList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/choose-experts
     * @summary Статистика по всему опросу (этап выбор экпертов)
     * @param {PostV1AnalyticsOverviewChooseExperts} postV1AnalyticsOverviewChooseExperts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts: PostV1AnalyticsOverviewChooseExperts, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewChooseExperts_2(postV1AnalyticsOverviewChooseExperts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/send-notification/choose-experts
     * @summary Отправка письма сотрудникам (этап выбра экперта)
     * @param {PostV1AnalyticsOverviewSendNotificationChooseExperts} postV1AnalyticsOverviewSendNotificationChooseExperts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts: PostV1AnalyticsOverviewSendNotificationChooseExperts, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewSendNotificationChooseExperts(postV1AnalyticsOverviewSendNotificationChooseExperts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/send-notification/start-survey
     * @summary Отправка письма сотрудникам (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewSendNotificationStartSurvey(postV1AnalyticsOverviewSendNotificationStartSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/send-notification/start-survey
     * @summary Отправка письма сотрудникам (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewSendNotificationStartSurvey} postV1AnalyticsOverviewSendNotificationStartSurvey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey: PostV1AnalyticsOverviewSendNotificationStartSurvey, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewSendNotificationStartSurvey_3(postV1AnalyticsOverviewSendNotificationStartSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/start-survey
     * @summary Статистика по всему опросу (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewStartSurvey(postV1AnalyticsOverviewStartSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/start-survey-list
     * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewStartSurveyList(postV1AnalyticsOverviewStartSurveyList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/start-survey-list
     * @summary Список сотрудников-экспертов с детализацией (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewStartSurveyList} postV1AnalyticsOverviewStartSurveyList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList: PostV1AnalyticsOverviewStartSurveyList, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewStartSurveyList_4(postV1AnalyticsOverviewStartSurveyList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/analytics/overview/start-survey
     * @summary Статистика по всему опросу (этап прохождение опроса)
     * @param {PostV1AnalyticsOverviewStartSurvey} postV1AnalyticsOverviewStartSurvey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey: PostV1AnalyticsOverviewStartSurvey, options?: any) {
        return DefaultApiFp(this.configuration).postV1AnalyticsOverviewStartSurvey_5(postV1AnalyticsOverviewStartSurvey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples
     * @summary Создание пары c current_user
     * @param {PostV1Couples} postV1Couples 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1Couples(postV1Couples: PostV1Couples, options?: any) {
        return DefaultApiFp(this.configuration).postV1Couples(postV1Couples, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/by_batch
     * @summary Массовое создание пар
     * @param {PostV1CouplesByBatch} postV1CouplesByBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesByBatch(postV1CouplesByBatch: PostV1CouplesByBatch, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesByBatch(postV1CouplesByBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/:couple_id/assessment/answer
     * @summary Сохранение оценки
     * @param {number} coupleId ID пары
     * @param {PostV1CouplesCoupleIdAssessmentAnswer} postV1CouplesCoupleIdAssessmentAnswer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesCoupleIdAssessmentAnswer(coupleId: number, postV1CouplesCoupleIdAssessmentAnswer: PostV1CouplesCoupleIdAssessmentAnswer, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesCoupleIdAssessmentAnswer(coupleId, postV1CouplesCoupleIdAssessmentAnswer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/:couple_id/assessment/done
     * @summary Оценка завершена
     * @param {number} coupleId ID пары
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesCoupleIdAssessmentDone(coupleId: number, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesCoupleIdAssessmentDone(coupleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/:couple_id/move-to-expert-list
     * @summary Перемещение пары для экперта на этапе прохождения
     * @param {number} coupleId ID пары
     * @param {PostV1CouplesCoupleIdMoveToExpertList} postV1CouplesCoupleIdMoveToExpertList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesCoupleIdMoveToExpertList(coupleId: number, postV1CouplesCoupleIdMoveToExpertList: PostV1CouplesCoupleIdMoveToExpertList, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesCoupleIdMoveToExpertList(coupleId, postV1CouplesCoupleIdMoveToExpertList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/:couple_id/regain
     * @summary Перенос из rejected в pending
     * @param {number} coupleId ID пары
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesCoupleIdRegain(coupleId: number, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesCoupleIdRegain(coupleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/:couple_id/rejected
     * @summary Отказ от оценки
     * @param {number} coupleId ID пары
     * @param {PostV1CouplesCoupleIdRejected} postV1CouplesCoupleIdRejected 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesCoupleIdRejected(coupleId: number, postV1CouplesCoupleIdRejected: PostV1CouplesCoupleIdRejected, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesCoupleIdRejected(coupleId, postV1CouplesCoupleIdRejected, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/couples/favorite
     * @summary Добавить пары в избранное
     * @param {PostV1CouplesFavorite} postV1CouplesFavorite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1CouplesFavorite(postV1CouplesFavorite: PostV1CouplesFavorite, options?: any) {
        return DefaultApiFp(this.configuration).postV1CouplesFavorite(postV1CouplesFavorite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/invitations
     * @summary Получение JWT по токену приглашения
     * @param {PostV1Invitations} postV1Invitations 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1Invitations(postV1Invitations: PostV1Invitations, options?: any) {
        return DefaultApiFp(this.configuration).postV1Invitations(postV1Invitations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/invitees
     * @summary Добавление внешнего эксперта к оцениваемому (создание пары)
     * @param {PostV1Invitees} postV1Invitees 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1Invitees(postV1Invitees: PostV1Invitees, options?: any) {
        return DefaultApiFp(this.configuration).postV1Invitees(postV1Invitees, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/reviews/:survey_id/all
     * @summary Согласование всех экспертов у всех оцениваемых
     * @param {number} surveyId ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1ReviewsSurveyIdAll(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).postV1ReviewsSurveyIdAll(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/reviews/:survey_id/approve/:surveyee_id
     * @summary Согласование всех экспертов у оцениваемого
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1ReviewsSurveyIdApproveSurveyeeId(surveyId: number, surveyeeId: string, options?: any) {
        return DefaultApiFp(this.configuration).postV1ReviewsSurveyIdApproveSurveyeeId(surveyId, surveyeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/reviews/:survey_id/:surveyee_id/create_reviewer
     * @summary Создать у оцениваемого ревью с ответственным
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {string} reviewerId ID ответственного
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any) {
        return DefaultApiFp(this.configuration).postV1ReviewsSurveyIdSurveyeeIdCreateReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v1/yandex_gpt/weakness
     * @summary Ручка для генерации и получения плана развития топ-3 проседающих компетенций ГПТ
     * @param {PostV1YandexGptWeakness} postV1YandexGptWeakness 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postV1YandexGptWeakness(postV1YandexGptWeakness: PostV1YandexGptWeakness, options?: any) {
        return DefaultApiFp(this.configuration).postV1YandexGptWeakness(postV1YandexGptWeakness, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /v1/analytics/feedback/development_tasks/:id
     * @summary Обновление задачи ИПР
     * @param {number} id ID задачи ИПР
     * @param {number} surveyId ID опроса
     * @param {PutV1AnalyticsFeedbackDevelopmentTasksId} putV1AnalyticsFeedbackDevelopmentTasksId 
     * @param {string} [userId] ID сотрудника (иначе current_user из JWT токена)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putV1AnalyticsFeedbackDevelopmentTasksId(id: number, surveyId: number, putV1AnalyticsFeedbackDevelopmentTasksId: PutV1AnalyticsFeedbackDevelopmentTasksId, userId?: string, options?: any) {
        return DefaultApiFp(this.configuration).putV1AnalyticsFeedbackDevelopmentTasksId(id, surveyId, putV1AnalyticsFeedbackDevelopmentTasksId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /v1/reviews/:survey_id/:surveyee_id/update_reviewer
     * @summary Заменить у оцениваемого ответственного в ревью
     * @param {number} surveyId ID опроса
     * @param {string} surveyeeId ID оцениваемого
     * @param {string} reviewerId ID ответственного
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId: number, surveyeeId: string, reviewerId: string, options?: any) {
        return DefaultApiFp(this.configuration).putV1ReviewsSurveyIdSurveyeeIdUpdateReviewer(surveyId, surveyeeId, reviewerId, options).then((request) => request(this.axios, this.basePath));
    }
}


